<script>
import AppraisalItem from '@maxsystems/xplat/web/routes/SRP/components/AppraisalItem.vue'
import ToolbarSRP from '@maxsystems/xplat/web/routes/SRP/components/ToolbarSRP.vue'

import VirtualScroll from './components/VirtualScroll'

export default {
  name: 'SRP',
  components: {
    AppraisalItem,
    ToolbarSRP,
    VirtualScroll
  },
  inject: ['dataSource', 'uiState'],
  data: () => ({
    itemHeight: 97,
    currentAppraisalId: null
  }),
  computed: {
    sidebar: ({ uiState }) => uiState.sidebar,
    virtualScrollTopPosition: vm => vm.$refs.scroll.$el.getBoundingClientRect().top,
    appraisalIndexById: vm => Object.fromEntries(vm.dataSource.appraisals.map((appraisal, index) => [appraisal.id, index])),
    currentAppraisalIndex: vm => vm.appraisalIndexById[vm.currentAppraisalId]
  },
  watch: {
    'dataSource.appraisals.length' () {
      this.currentAppraisalId = this.$route.params.id
      this.scrollTo(this.currentAppraisalIndex)
    },
    '$vuetify.breakpoint.mobile' (isMobile) {
      if (isMobile) return
      this.sidebar.open()
    }
  },
  methods: {
    updateSidebar (value) {
      if (!this.$vuetify.breakpoint.mobile) return
      this.sidebar[value ? 'open' : 'close']()
    },
    allowClickOutside: () => [...document.querySelectorAll('.v-dialog--active')],
    scrollTo (index, duration = 0) {
      const centerPosition = Math.max(0,
        (index * this.itemHeight) -
        this.virtualScrollTopPosition +
        (this.itemHeight / 2)
      )
      this.$vuetify.goTo(centerPosition, { container: this.$refs.scroll, duration })
    },
    navigate (event) {
      this.currentAppraisalId = this.$route.params.id
      const nextIndex = this.currentAppraisalIndex + (['ArrowDown', 'Down'].includes(event.key) ? 1 : -1)
      const nextId = this.dataSource.appraisals[nextIndex]?.id
      const nextEl = this.$refs.scroll.$children.find(appraisal => appraisal.appraisal.id === nextId)
      if (!nextEl) return
      this.$router.push({ name: 'vdp', params: { id: nextId } })
      this.scrollTo(nextIndex, 500)
      this.$refs.scroll.$el.focus()
    }
  }
}
</script>

<template>
  <VNavigationDrawer
    v-click-outside="{
      handler: updateSidebar(sidebar.isOpen),
      include: allowClickOutside,
    }"
    :value="!sidebar.isSoftClosed"
    app
    class="v-navigation-drawer--srp"
    clipped
    width="380px"
    @input="updateSidebar"
  >
    <ToolbarSRP />
    <VirtualScroll
      ref="scroll"
      tabindex="0"
      :bench="5"
      :items="dataSource.appraisals"
      :item-height="itemHeight"
      :style="{ outline: 'none' }"
      @keydown.prevent.up.down="navigate"
    >
      <template
        #default="{ item }"
      >
        <AppraisalItem
          :appraisal="item"
        />
      </template>
    </VirtualScroll>
  </VNavigationDrawer>
</template>

<style lang="scss">
.v-navigation-drawer--srp {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
