<script>
export default {
  inject: ['theme'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    schema: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    valueAsIndex: vm => +!vm.value.boolean,
    color: ({ theme }) => theme.isDark ? '#B2410A' : '#F26522',
    inactiveColor: ({ theme }) => theme.isDark ? 'rgba(235 235 245 / 60%)' : '#000'
  },
  methods: {
    updateAnswer (index) {
      this.$emit('input', {
        ...this.value,
        boolean: !index
      })
    }
  }
}
</script>

<template>
  <div :class="[{ 'acv-detail__boolean__container--dense': dense }]">
    <VBtn
      v-for="(option, index) in schema.options"
      :key="option.id"
      depressed
      :disabled="disabled"
      :class="['acv-detail__boolean', {
        'mr-2': index === 0,
        'acv-detail__boolean--active': valueAsIndex === index
      }]"
      :outlined="valueAsIndex !== index"
      :color="(valueAsIndex === index && color) || inactiveColor"
      rounded
      :ripple="false"
      @click="updateAnswer(index)"
    >
      <VIcon
        v-if="valueAsIndex === index"
        left
      >
        $complete
      </VIcon>
      {{ option.description }}
    </VBtn>
  </div>
</template>
<style lang="scss">
.acv-detail__boolean {
  &__container--dense {
    display: flex;
    width: 100%;

    .acv-detail__boolean {
      flex: 1;
    }
  }

  &--active {
    color: #FFF !important;
  }
}

.theme--dark {
  .acv-detail__boolean {
    &--active {
      color: #CDE5FF;
    }
  }
}
</style>
