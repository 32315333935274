
<script>
export default {
  name: 'NavbarVDP',
  inject: ['dataSource', 'uiState'],
  computed: {
    appraisals: vm => vm.dataSource.appraisals,
    currentId: vm => vm.$route.params.id ?? 0,
    currentItemIndex: vm => vm.appraisals.findIndex(appraisal => appraisal.id === vm.currentId),
    previousId: vm => vm.appraisals[vm.currentItemIndex - 1]?.id,
    nextId: vm => vm.appraisals[vm.currentItemIndex + 1]?.id
  }
}
</script>

<template>
  <nav class="vdp-navbar d-flex justify-space-between">
    <div class="pa-2">
      <button
        ref="back"
        class="vdp-navbar__back-label text-decoration-none primary--text"
        @click="uiState.sidebar.toggle"
      >
        <VIcon
          class="primary--text d-inline-block"
          v-text="'$chevronBack'"
        />My Appraisals
      </button>
    </div>
    <div class="d-flex">
      <router-link
        :is="!previousId ? 'span' : 'router-link'"
        ref="previous"
        class="vdp-navbar__button"
        :to="{ name: 'vdp', params: { id: previousId } }"
      >
        <VIcon
          class="primary--text"
          :disabled="!previousId"
          v-text="'$chevronUp'"
        />
      </router-link>
      <router-link
        :is="!nextId ? 'span' : 'router-link'"
        ref="next"
        class="vdp-navbar__button"
        :to="{ name: 'vdp', params: { id: nextId } }"
      >
        <VIcon
          class="primary--text"
          :disabled="!nextId"
          v-text="'$chevronDown'"
        />
      </router-link>
    </div>
  </nav>
</template>

<style lang="scss">
.vdp-navbar {
  border-bottom: 1px solid;
  user-select: none;

  &__back-label {
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
  }

  &__button {
    margin-right: 8px;
    padding: 7px;
  }
}

.theme--light .vdp-navbar {
  border-color: rgb(0 0 0 / 12%);
}

.theme--dark .vdp-navbar {
  border-color: rgb(255 255 255 / 12%);
}
</style>
