<script>
import ToolbarBtnBase from '@maxsystems/xplat/core/routes/VDP/components/Header/ToolbarVDP/ToolbarBtn.base.vue'

export default {
  mixins: [ToolbarBtnBase]
}
</script>

<template>
  <VBtn
    text
    height="40"
    width="40"
    class="toolbar-vdp__button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </VBtn>
</template>

<style lang="scss">
.toolbar-vdp__button {
  border-radius: 4px !important;

  &:not(.v-btn--round).v-size--default {
    min-width: unset;
    padding: 0;
  }

  .v-icon__svg {
    transition: inherit;
  }

  &:hover,
  &:focus {
    .v-icon__svg {
      fill: var(--v-primary-base);
    }
  }
}

.theme--dark {
  .toolbar-vdp__button {
    &:not(:focus):not(:hover) .v-btn__content {
      opacity: 0.62;
    }
  }
}
</style>
