<script>
import { VIN } from '@maxsystems/vin'

export default {
  name: 'CreateAppraisal',
  data: () => ({
    vinLength: 17,
    error: '',
    rules: {
      vin: [value => (value && new VIN(value).isValid) || 'Invalid VIN']
    },
    step: '0',
    steps: [
      { label: 'VIN', active: true },
      { label: 'License Plate', active: false },
      { label: 'Year Make Model', active: false }
    ],
    vin: ''
  }),
  computed: {
    details () {
      const { year, make, model } = this.vin ? new VIN(this.vin) : {}
      return [year, make, model].filter(Boolean).join(' ').trim()
    },
    isCreatePending: ({ dataSource }) => dataSource.queries.createAppraisal.isPending()
  }
}
</script>
