<script>
import { mdiCheck } from '@mdi/js'

import { addIcons } from '@maxsystems/ui/vue'

import ACVEstimateHeader from './components/ACVEstimateHeader.vue'
import FieldBoolean from './components/FieldBoolean.vue'
import FieldRadio from './components/FieldRadio.vue'

addIcons({
  complete: mdiCheck
})

export default {
  name: 'ACVEstimateWidgetDetail',
  components: {
    ACVEstimateHeader,
    FieldBoolean,
    FieldRadio
  },
  inject: ['theme'],
  data: () => ({
    withCondition: false,
    titleBranded: false,
    bodyDamage: false
  }),
  computed: {
    color: ({ theme }) => theme.isDark ? '#B2410A' : '#F26522',
    dense () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<template>
  <div
    :class="['acv-detail fill-height', {'acv-detail--dense': dense}]"
  >
    <ACVEstimateHeader
      :value="38500"
      :dense="dense"
    />
    <div class="acv-detail__form fill-height black--text">
      <div class="acv-detail__title font-weight-medium pb-1">
        Verify Condition Information
      </div>
      <div class="acv-detail__subtitle pb-5">
        Please confirm the information below
      </div>
      <div class="acv-detail__board">
        <div class="acv-detail__board-header pb-4">
          <div class="acv-detail__board-header-title" />
          <div class="acv-detail__board-header-title center font-weight-medium">
            Dealer condition confirmation
          </div>
          <div class="acv-detail__board-header-title font-weight-medium">
            Customer answer
          </div>
        </div>
        <div class="acv-detail__board-item mb-2">
          <div class="acv-detail__board-item-title font-weight-medium">
            Title Branded
          </div>
          <div class="acv-detail__board-item-sections">
            <div class="acv-detail__board-item-section acv-detail__board-item-subtitle pb-1">
              Vehicle involved in an incident that required insurance involvments
            </div>
            <div
              v-if="withCondition"
              class="acv-detail__board-item-section acv-detail__board-customer-condition"
            >
              <div class="d-flex align-baseline">
                <div>
                  <div class="acv-detail__condition-answer">
                    Customer answer
                  </div>
                </div>
                <div class="ml-2">
                  <div class="acv-detail__condition-value customer-answer">
                    Yes
                  </div>
                </div>
              </div>
              <VDivider />
            </div>
            <div :class="['acv-detail__board-item-section acv-detail__customer-action center', {'no-condition': !withCondition} ]">
              <div class="acv-detail__customer-action-container">
                <FieldBoolean
                  :color="color"
                  :value="{ boolean: titleBranded }"
                  :dense="dense"
                  :schema="{
                    options: [ { description: 'Yes' }, { description: 'No' }]
                  }"
                  @input="({ boolean }) => titleBranded = boolean"
                />
              </div>
            </div>
            <div class="acv-detail__board-item-section acv-detail__customer-answer text-capitalize">
              yes
            </div>
          </div>
        </div>
        <div class="acv-detail__board-item mb-2">
          <div class="acv-detail__board-item-title font-weight-medium">
            Body Damage
          </div>
          <div class="acv-detail__board-item-sections">
            <div class="acv-detail__board-item-section acv-detail__board-item-subtitle pb-1">
              Description
            </div>
            <div
              v-if="withCondition"
              class="acv-detail__board-item-section acv-detail__board-customer-condition"
            >
              <div class="d-flex align-baseline">
                <div>
                  <div class="acv-detail__condition-answer">
                    Customer answer
                  </div>
                  <div class="acv-detail__condition-level">
                    Level of Damage
                  </div>
                </div>
                <div class="ml-2">
                  <div class="acv-detail__condition-value customer-answer">
                    Yes
                  </div>
                  <div class="acv-detail__condition-value">
                    Minor
                  </div>
                </div>
              </div>
              <VDivider />
            </div>
            <div :class="['acv-detail__board-item-section acv-detail__customer-action center', {'no-condition': !withCondition} ]">
              <div class="acv-detail__customer-action-container">
                <FieldBoolean
                  :color="color"
                  :value="{ boolean: bodyDamage }"
                  :dense="dense"
                  :schema="{
                    options: [{ description: 'Yes' }, { description: 'No' }]
                  }"
                  @input="({ boolean }) => bodyDamage = boolean"
                />
              </div>
              <div class="acv-detail__customer-action-option">
                <div class="acv-detail__option-title my-2">
                  Level of damage
                </div>
                <FieldRadio
                  :color="color"
                  :value="{ text: 'Moderate' }"
                  :schema="{
                    options: [
                      { description: 'Minor', order: 1 },
                      { description: 'Major', order: 2 },
                      { description: 'Moderate', order: 3 },
                    ]
                  }"
                />
              </div>
            </div>
            <div class="acv-detail__board-item-section acv-detail__customer-answer">
              <div class="mb-5">
                Yes
              </div>
              <div class="acv-detail__option-title">
                Level of damage
              </div>
              <div class="acv-detail__option-answer">
                Minor
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="acv-detail__footer">
      <VBtn
        :color="theme.isDark ? '#004066' : '#004E7D'"
        class="acv-detail__confirmation white--text"
        elevation="0"
      >
        Update
      </VBtn>
    </div>
  </div>
</template>

<style lang="scss">
.acv-detail {
  padding-bottom: 86px;
  position: relative;

  &__board-header-title {
    font-size: 20px;
    line-height: 23px;
  }

  &__board-customer-condition {
    margin-bottom: 23px;
  }

  &__footer {
    align-items: center;
    background-color: #FFF;
    bottom: 0;
    display: flex;
    height: 86px;
    justify-content: flex-end;
    padding: 0 2rem;
    position: absolute;
    width: 100%;
  }

  &__confirmation {
    border-radius: 100px;
    height: 46px !important;
    padding: 16px 65px !important;
  }

  &__form {
    background-color: #F2F2F6;
    overflow: auto;
    padding: 24px;
  }

  &__title {
    line-height: 18.75px;
  }

  &__subtitle {
    color: rgba(0 0 0 / 60%);
    letter-spacing: 0.15px;
    line-height: 16.41px;
  }

  &__board-header {
    display: flex;
  }

  &__board-header-title,
  &__board-item-section {
    flex: 2;

    &.center {
      flex: 3;
    }
  }

  &__board-item {
    background-color: #FFF;
    border: 1px solid #E4E4E4;
    border-radius: 14px;
    padding: 12px 16px;
  }

  &__board-item-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 4px;
  }

  &__board-item-subtitle {
    color: rgba(60 60 67 / 60%);
    font-size: 13px;
    letter-spacing: -0.078px;
    line-height: 18px;
  }

  &__board-item-sections {
    display: flex;
  }

  &__customer-action,
  &__customer-answer {
    display: flex;
    flex-direction: column;
  }

  &__customer-action {
    justify-content: center;
  }

  &__customer-answer {
    align-items: start;
    font-size: 17px;
    line-height: 22px;
    padding-left: 32px;
  }

  &__customer-action-container {
    display: flex;
    gap: 10px;
  }

  &__spacer {
    margin-bottom: 13px;
  }

  &__option-title {
    color: rgba(0 0 0 / 60%);
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  &__option-answer {
    font-size: 13px;
    letter-spacing: -0.078px;
    line-height: 18px;
    margin-top: 6px;
  }

  &__condition-answer,
  &__condition-level,
  &__condition-value {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 11px;
  }

  &__condition-level {
    color: rgba(0 0 0 / 60%);
  }

  &__condition-value {
    color: #000;

    &.customer-answer {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .v-divider {
    margin-top: 5px;
  }

  &--dense {
    .acv-detail {
      &__customer-action {
        &.no-condition {
          margin-top: 13px;
        }
      }

      &__board-item-subtitle {
        margin-bottom: 7px;
      }

      &__footer {
        justify-content: center;
        padding: 0;
      }

      &__option-title {
        font-size: 12px;
        line-height: 16px;
        padding: 8px 0;
      }

      &__form {
        padding: 16px;
      }

      &__subtitle {
        font-size: 14px;
      }

      &__board-header,
      &__customer-answer {
        display: none;
      }

      &__board-item,
      &__board-item-sections {
        flex-direction: column;
      }

      &__board-item-sections {
        margin-bottom: 12px;
      }
    }
  }
}

.theme--dark {
  .acv-detail {
    &__footer,
    &__board-item {
      background-color: #1C1C1E;
    }

    &__subtitle,
    &__board-item-subtitle,
    &__condition-level,
    &__option-title {
      color: rgba(235 235 245 / 60%);
    }

    &__form {
      background-color: #000;
    }

    &__title,
    &__board-item-title,
    &__board-header-title,
    &__condition-answer,
    &__condition-value,
    &__customer-answer {
      color: #FFF;
    }

    &__board-item {
      border: 1px solid #2C2C2E;
    }
  }

  .v-divider {
    border-color: #3A3A3C;
  }
}
</style>
