<script>
import ToolbarSRPBase from '@maxsystems/xplat/core/routes/SRP/components/ToolbarSRP.base.vue'

import DealerSwitcher from './DealerSwitcher'
import NewAppraisalDialog from './NewAppraisalDialog/NewAppraisalDialog.vue'
import SearchForm from '~/components/SearchForm'

export default {
  components: {
    NewAppraisalDialog,
    DealerSwitcher,
    SearchForm
  },
  mixins: [ToolbarSRPBase],
  data: () => ({
    showCreateDialog: false
  }),
  methods: {
    closeCreateDialog (newAppraisalId) {
      this.showCreateDialog = false
      /**
       * Creating a new appraisal with an existing VIN returns a link to the existing appraisal.
       * This check is to ensure we avoid a NavigationDuplicated exception from vue-router.
       */
      if (newAppraisalId && this.$route.params?.id !== newAppraisalId) {
        this.$router.push({ name: 'vdp', params: { id: newAppraisalId } })
      }
    }
  }
}
</script>

<template>
  <section class="toolbar--srp">
    <VProgressLinear
      :value="(dataSource.appraisals.length / dataSource.totalCount) * 100"
      :indeterminate="dataSource.totalCount === 0"
      :active="dataSource.queries.getAppraisals.isPending()"
      absolute
      height="2"
      query
    />
    <div class="toolbar--srp__title">
      <VRow>
        <VCol :cols="10">
          <VRow v-if="dealerNodes.length > 1">
            <VCol>
              <DealerSwitcher
                :dealer-nodes="dealerNodes"
                :current-dealer="currentDealer"
              />
            </VCol>
          </VRow>
          <h2 v-else>
            Appraisals
          </h2>
          <h3>
            {{ dataSource.totalCount }} vehicles,
            {{ dataSource.new.length }} new
          </h3>
        </VCol>
        <VCol
          :cols="2"
          class="d-flex align-center"
        >
          <VDialog
            v-model="showCreateDialog"
            persistent
            max-width="30rem"
          >
            <template #activator="{ on }">
              <VBtn
                aria-label="Add New Appraisal"
                class="v-btn--new-appraisal"
                color="primary"
                icon
                v-on="on"
              >
                <VIcon
                  size="32"
                  v-text="'$add'"
                />
              </VBtn>
            </template>
            <NewAppraisalDialog
              @close="closeCreateDialog($event)"
            />
          </VDialog>
        </VCol>
      </VRow>
    </div>
    <SearchForm
      class="d-md-none"
      dense
    />
  </section>
</template>

<style lang="scss">
.toolbar--srp {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    height: 18px;
    opacity: 0.7;
  }

  &__title {
    color: #424242;
    margin: 1.5rem 1rem 1rem 2.375rem;
  }

  .v-text-field--search.v-input--dense {
    margin: 0 1rem 0.75rem 2.375rem;
  }

  &__dealer-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.v-btn.v-btn--new-appraisal.v-size--default {
  border-radius: 8px;
  border-width: 2px;
  height: 32px;
  min-width: unset;
  padding: 0;
  width: 32px;
}

.theme--dark {
  .toolbar--srp__title {
    color: unset;
  }
}
</style>
