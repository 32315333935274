<script>
export default {
  name: 'ACVLogo',
  props: {
    gradient: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="32"
    viewBox="0 0 72 32"
    fill="#FFF"
  >
    <defs>
      <linearGradient
        id="gradient"
        gradientUnits="userSpaceOnUse"
        x1="49.815"
        y1="34.254"
        x2="44.84"
        y2="6.009"
        gradientTransform="scale(.7912 .8)"
      >
        <stop
          offset="0"
          style="stop-color: #F26723;stop-opacity: 1;"
        />
        <stop
          offset=".332"
          style="stop-color: #E35323;stop-opacity: 1;"
        />
        <stop
          offset=".997"
          style="stop-color: #C61621;stop-opacity: 1;"
        />
        <stop
          offset="1"
          style="stop-color: #C61521;stop-opacity: 1;"
        />
      </linearGradient>
    </defs>
    <path
      :style="{ fill: gradient ? 'url(#gradient)': '' }"
      d="M37.54 21.098c-2.692 0-4.599-2.246-4.263-5.016.34-2.77 2.793-5.016 5.48-5.016 1.552 0 2.837.746 3.59 1.907l4.915-5.102c-1.801-2.219-4.543-3.598-7.766-3.598-6.285 0-12.016 5.247-12.805 11.723-.789 6.473 3.668 11.723 9.95 11.723 3.238 0 6.328-1.395 8.672-3.63l-4.356-4.284c-.96.8-2.164 1.293-3.418 1.293Zm0 0 M62.793 0h8.832L55.199 26.691h-8.832Zm0 0 M25.852 17.563c0-.543.03-1.094.097-1.653.086-.719.235-1.43.434-2.125l-.985-7.383-5.62 9.141.452 3.375h-2.543l-2.906 4.738h6.078l.403 3.035h6.843l-.363-2.722c-1.25-1.84-1.902-4.047-1.89-6.407Zm0 0 M16.426 5.309h8.832L8.832 32H0Zm0 0 M50.313 5.309h-5.02c1.082.636 2.05 1.511 2.953 2.562l-3.902 4.043 1.84 13.79 5.62-9.138Zm0 0"
    />
  </svg>
</template>
