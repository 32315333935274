import { VProgressCircular, VContainer } from 'vuetify/lib'

import DevLogo from './DevLogo'

export default {
  name: 'Loading',
  inject: ['theme'],
  render (h) {
    return [
      h(VContainer, {
        class: ['justify-center', 'flex-column', 'fill-height']
      }, [
        h(DevLogo, { props: { size: 32 } }),
        h(VProgressCircular, {
          class: 'ma-4',
          props: {
            color: this.theme.isDark ? 'light' : 'dark',
            indeterminate: true,
            size: 18,
            width: 2
          }
        })
      ])
    ]
  }
}
