<script>
export default {
  props: {
    schema: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateAnswer (value) {
      this.$emit('input', {
        ...this.value,
        text: value
      })
    }
  }
}
</script>

<template>
  <VRadioGroup
    class="acv-detail__radio mt-0 pt-0"
    row
    hide-details
    :value="value.text"
    @change="updateAnswer"
  >
    <VRadio
      v-for="option in schema.options"
      :key="option.id"
      :label="option.description"
      :value="option.description"
      :off-icon="null"
      :on-icon="'$complete'"
      :ripple="false"
    />
  </VRadioGroup>
</template>
<style lang="scss">
.acv-detail__radio {
  .v-input--radio-group__input {
    gap: 8px;
  }

  .v-radio {
    border: 1px solid #212121;
    border-radius: 8px;
    margin-right: 0 !important;
    padding: 4px 16px;
    position: relative;
    transition-duration: 0.28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &::before {
      background-color: currentcolor;
      border-radius: inherit;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover::before {
      opacity: 0.04;
    }
  }

  .v-radio.v-item--active {
    border: 1px solid #004E7D;
  }

  .v-label {
    color: #545454;
    font-size: 14px;
    line-height: 21px;
  }

  .v-input--selection-controls__input {
    display: none;
  }

  .v-item--active {
    background-color: #EBF6FF;
    padding: 4px 0;
    padding-left: 5px;
    padding-right: 15px;

    .v-input--selection-controls__input {
      display: block;
      margin-left: -1px;
      margin-top: -5px;
    }

    .v-label,
    .v-icon {
      color: #004E7D !important;
    }

    .v-icon__svg {
      width: 20px;
    }
  }
}

.theme--dark {
  .acv-detail__radio {
    .v-radio {
      border: 1px solid rgba(235 235 245 / 60%);
    }

    .v-label {
      color: rgba(235 235 245 / 60%);
    }

    .v-item--active {
      background-color: #004A79;

      .v-label,
      .v-icon {
        color: #CDE5FF !important;
      }
    }
  }
}
</style>
