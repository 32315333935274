<script>
import Vue from 'vue'

import { number } from '@maxsystems/ui/vue/filters'

export default Vue.extend({
  name: 'HeaderVDP',
  filters: {
    number
  },
  inject: ['dataSource'],
  props: {
    id: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    trimSelection: null,
    mileageValue: null,
    appraisalType: null
  }),
  computed: {
    appraisal: vm => vm.dataSource.nodes[vm.id] || {},
    mileage: vm => vm.vehicle.odometer?.value,
    mileageFormatted: {
      get: ({ mileageValue }) => number(mileageValue),
      set (newValue) {
        this.mileageValue = newValue
      }
    },
    title: vm => {
      const { year, make, model } = vm.vehicle.ymmt || {}
      return [year, make, model].join(' ').trim()
    },
    trim: vm => vm.vehicle.ymmt?.trim?.trim(),
    type: vm => vm.appraisal.type,
    vehicle: vm => vm.appraisal.vehicle || {},
    vin: vm => vm.vehicle.vin
  },
  watch: {
    id: {
      handler () {
        this.init()
      },
      immediate: true
    }
  },
  methods: {
    init () {
      this.trimSelection = { name: this.trim || '-', value: this.trim }
      this.mileageValue = this.mileage
      this.appraisalType = { name: this.type, value: this.type }
    }
  }
})
</script>
