<script>
import { mdiInformationOutline } from '@mdi/js'

import { addIcons } from '@maxsystems/ui/vue'
import { currency } from '@maxsystems/ui/vue/filters'

import ACVLogo from '../../ACVLogo.vue'

addIcons({
  informationOutline: mdiInformationOutline
})

export default {
  name: 'ACVEstimateHeader',
  filters: {
    currency
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    dense: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    ACVLogo
  })
}
</script>

<template>
  <div :class="['acv-detail__header d-flex align-center', {'acv-detail__header--dense': dense}]">
    <component
      :is="ACVLogo"
      gradient
      class="acv-detail__header__icon"
    />
    <span class="acv-detail__header__title font-weight-regular mr-7">Estimate</span>
    <template v-if="value !== null">
      <span class="acv-detail__header__value text-h5 d-flex align-center">
        {{ value | currency }}
        <VTooltip v-bind="{ [dense ? 'bottom' : 'right']: true }">
          <template #activator="{ on, attrs }">
            <VIcon
              class="ml-2"
              small
              v-bind="attrs"
              v-on="on"
              v-text="'$informationOutline'"
            />
          </template>
          <span>Estimate may change based on vehicle condition</span>
        </VTooltip>
      </span>
    </template>
  </div>
</template>
<style lang="scss">
.acv-detail__header {
  padding: 18px 24px;

  &__title {
    font-size: 18px;
    line-height: 21px;
  }

  &__icon {
    height: 27px;
    width: 60px;
  }

  &__value {
    line-height: 28px !important;
  }

  &--dense {
    padding: 18px 16px;
  }
}

.theme--dark {
  .acv-detail__header {
    background-color: #1C1C1E;
  }
}
</style>
