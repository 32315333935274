<script>
import DevLogo from './DevLogo'
import MenuHelp from './MenuHelp'
import SearchForm from './SearchForm'

export default {
  name: 'ToolbarApp',
  components: {
    DevLogo,
    MenuHelp,
    SearchForm
  },
  inject: ['theme', 'uiState']
}
</script>

<template>
  <VAppBar
    app
    clipped-left
    clipped-right
    flat
  >
    <div class="v-toolbar__content__left">
      <DevLogo
        size="32"
      />
      <VSpacer
        class="hidden-xs-only"
        width="108"
      />
      <VBtn
        class="v-btn--sidebar hidden-xs-only"
        text
        width="40"
        height="40"
        @click="uiState.sidebar.toggle"
      >
        <VIcon
          v-text="'$sidebar'"
        />
      </VBtn>
    </div>
    <SearchForm
      class="d-none d-md-inline-block ml-4"
    />
    <VSpacer />
    <VMenu
      bottom
      content-class="v-menu__content--popover"
      nudge-left="28"
      nudge-bottom="4"
      offset-y
      transition="slide-y-transition"
    >
      <template #activator="{ on, attrs }">
        <VBtn
          class="hidden-xs-only"
          text
          width="40"
          height="40"
          v-bind="attrs"
          v-on="on"
        >
          <VIcon v-text="'$help'" />
        </VBtn>
      </template>
      <MenuHelp />
    </VMenu>
    <VBtn
      class="hidden-xs-only"
      text
      width="40"
      height="40"
    >
      <VIcon
        v-text="'$settings'"
      />
    </VBtn>
    <VBtn
      class="ml-8 hidden-xs-only"
      text
      width="40"
      height="40"
    >
      <VIcon
        v-text="'$notifications'"
      />
    </VBtn>
    <VAvatar
      class="ml-2"
      color="primary"
      size="32"
    >
      <img src="https://avatars.githubusercontent.com/u/1577802?v=4">
    </VAvatar>
  </VAppBar>
</template>

<style lang="scss">
.v-app-bar {
  border-bottom: 1px solid;

  .v-toolbar__content {
    padding-left: 0;

    &__left {
      align-items: center;
      border-right: 1px solid transparent;
      display: flex;
      padding: 0 12px 0 16px;

      @include breakpoint("sm-and-up") {
        width: 380px;
      }
    }
  }

  &.theme--light {
    background: #FFF !important;
    border-color: rgb(0 0 0 / 12%);
  }

  &.theme--dark {
    border-color: rgb(255 255 255 / 12%);
  }

  button.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset;
    padding: 0;

    &:not(:focus):not(:hover) .v-btn__content {
      opacity: 0.62;
    }
  }

  .v-text-field--solo {
    &:not(.v-input--is-focused) > .v-input__control > .v-input__slot {
      box-shadow: none !important;
    }

    &.v-input--is-focused.theme--dark > .v-input__control > .v-input__slot {
      background-color: #363636;
    }
  }
}
</style>
