var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CupertinoWidget',{staticClass:"cupertino__widget--carfax",attrs:{"type":_vm.mustLogin ? 'warning' : undefined,"to":!_vm.mustLogin
      ? {
        name: 'vdp.widget.carfax.detail',
        params: { id: _vm.$route.params.id, widgetId: 'carfax' },
      }
      : undefined},on:{"click":_vm.checkMustLogin}},[_c('CarfaxLogo',{staticClass:"carfax__logo"}),(_vm.isLoading || _vm.hasReport)?_c('span',{staticClass:"carfax__pillars"},_vm._l((_vm.fourPillars),function(ref,i){
      var icon = ref.icon;
      var text = ref.text;
return _c('VSkeletonLoader',{key:(i + "-" + (icon.split('/').pop())),staticClass:"pillar",attrs:{"loading":!icon,"type":"image"}},[_c('img',{staticClass:"pillar__icon",attrs:{"src":icon,"alt":text,"title":text}})])}),1):_c('span',{staticClass:"pillar__label"},[_vm._v(_vm._s(_vm.mustLogin ? "Login Required" : "No Report Found"))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }