<script>
import ToolbarBtnShareBase from '@maxsystems/xplat/core/routes/VDP/components/Header/ToolbarVDP/ToolbarBtnShare.base.vue'

import ToolbarBtn from './ToolbarBtn.vue'

export default {
  components: {
    ToolbarBtn
  },
  mixins: [ToolbarBtnShareBase],

  data: () => ({
    snackbar: false,
    // allow storybook to set different timeout value
    // otherwise use Vuetify default
    snackbarTimeout: undefined
  }),
  computed: {
    webShareApiSupported: () => !!navigator.share
  },

  methods: {
    async share () {
      if (this.webShareApiSupported) {
        try {
          await navigator.share({
            ...this.shareData,
            url: window.location.href
          })
        } catch (err) {
          // TODO: handle errors?
        }
      } else {
        await navigator.clipboard.writeText(window.location.href)
        this.snackbar = true
      }
    }
  }
}
</script>

<template>
  <section>
    <ToolbarBtn @click="share">
      <VIcon
        v-text="
          'M6.83 21h10.34c.943 0 1.65-.23 2.118-.691.475-.461.712-1.146.712-2.056V9.409c0-.91-.237-1.595-.712-2.055-.469-.461-1.175-.692-2.118-.692h-2.515V8.09h2.487c.445 0 .79.118 1.037.355.247.236.37.584.37 1.045v8.684c0 .461-.123.81-.37 1.046-.246.236-.592.354-1.037.354H6.848c-.45 0-.796-.118-1.036-.354s-.36-.585-.36-1.046V9.49c0-.46.12-.81.36-1.045.24-.237.586-.355 1.036-.355h2.497V6.662H6.83c-.943 0-1.652.23-2.127.692C4.234 7.808 4 8.494 4 9.409v8.844c0 .916.234 1.6.703 2.056.475.46 1.184.691 2.127.691Zm5.165-6.938a.693.693 0 0 0 .505-.204.647.647 0 0 0 .217-.488v-9.1l-.055-1.33.604.63 1.37 1.435a.622.622 0 0 0 .487.213.66.66 0 0 0 .47-.177.592.592 0 0 0 .188-.452c0-.166-.072-.32-.216-.461L12.518 1.24a.863.863 0 0 0-.261-.186.667.667 0 0 0-.523 0 1.038 1.038 0 0 0-.261.186L8.426 4.128a.65.65 0 0 0-.207.46c0 .184.06.335.18.453a.64.64 0 0 0 .469.177c.204 0 .37-.07.495-.213l1.362-1.435.613-.63-.055 1.33v9.1c0 .19.07.352.208.488a.713.713 0 0 0 .505.204Z'
        "
      />
    </ToolbarBtn>
    <VSnackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
    >
      Link copied to clipboard
    </VSnackbar>
  </section>
</template>
