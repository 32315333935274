<script>
export default {
  name: 'CarfaxWidget',
  inject: ['authData', 'appraisalData'],
  data: () => ({
    mustLogin: false,
    isLoading: true
  }),
  computed: {
    report: ({ appraisalData }) => appraisalData.node?.vehicle?.carfax || {},
    hasReport: ({ report }) => !!report.fourPillars,
    fourPillars: ({ isLoading, report }) =>
      isLoading ? Array(4).fill({ icon: '' }) : report.fourPillars,

    // override in mobile
    redirectUri: () => window.location,
    loginURL: ({ authData, redirectUri }) =>
      `https://api.firstlook.biz/v1/carfaxconnect/oauth/initiate/${authData.user.id}` +
      `?redirect_uri=${encodeURIComponent(redirectUri)}`
  },

  watch: {
    'appraisalData.id': {
      handler (a, b) {
        if (this.hasReport) {
          this.isLoading = false
          return
        }
        this.getReport()
      },
      immediate: true
    }
  },

  methods: {
    async getReport () {
      this.mustLogin = false
      this.isLoading = true
      const { errors } = await this.appraisalData.query(
        'vehicle { carfax { fourPillars { icon text } url } }'
      )
      this.mustLogin = Boolean(
        errors?.some(({ extensions }) => extensions.code === 'FORBIDDEN')
      )
      this.isLoading = false
    }
  }
}
</script>
