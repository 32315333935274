<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ToolbarBtnShare',
  inject: ['authData', 'appraisalData'],
  computed: {
    shareData: ({ appraisalData, authData }) => {
      const { year, make, model, trim } = appraisalData.node.vehicle.ymmt
      return {
        title: 'Appraisal Link',
        text: `Appraisal for ${year} ${make} ${model} ${trim} at ${authData.user.dealer.node.name}`
      }
    }
  }
})
</script>
