import { VVirtualScroll } from 'vuetify/lib'
import {
  convertToUnit,
  getSlot
} from 'vuetify/lib/util/helpers'

/**
 * @todo This file can be removed once this PR is merged:
 * https://github.com/vuetifyjs/vuetify/pull/15205
 * and available in a new Vuetify release.
 */
export default {
  name: 'VirtualScroll',
  extends: VVirtualScroll,
  data: () => ({
    currentFirstChild: 0
  }),
  computed: {
    virtualList () {
      return this.items.map((data, index) => ({
        __vvscrollerIndex: index,
        data
      }))
    }
  },
  methods: {
    getList (firstToRender) {
      return this.virtualList.slice(
        firstToRender,
        this.lastToRender
      )
    },
    getChildren () {
      const { __vvscrollerIndex: firstChildToRender } = this.virtualList[this.firstToRender] || {}
      const firstChild = firstChildToRender > 0 && this.currentFirstChild !== firstChildToRender
        ? this.firstToRender - 1
        : this.firstToRender

      this.currentFirstChild = firstChildToRender
      return this.getList(firstChild).map(this.genChild)
    },
    genChild (item, index) {
      index += this.firstToRender
      const top = convertToUnit(index * this.__itemHeight)
      const { __vvscrollerIndex, data } = item
      return this.$createElement('div', {
        staticClass: 'v-virtual-scroll__item',
        style: { top },
        key: __vvscrollerIndex
      }, getSlot(this, 'default', { index, item: data }))
    }
  }
}
