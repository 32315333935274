<script>
import { VDialog } from 'vuetify/lib'

export default {
  name: 'PopoverDialog',
  components: {
    VDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    value: {
      handler () {
        document.documentElement
          .classList[this.value ? 'add' : 'remove']('popover--visible')
      },
      immediate: true
    }
  },

  destroyed () {
    // make sure we clean up after ourselves
    document.documentElement.classList.remove('popover--visible')
  },

  render (h) {
    return h('div', [
      // Isolated CSS for Popover effect.
      // This way, we prevent them bleeding (e.g.: in Storybook) when they are not necessary.
      h('style', {
        domProps: {
          innerHTML: `
        html { background: #000; }
        .v-application { background: transparent !important; }
        .v-application--wrap { overflow: hidden; }
        .v-application.theme--dark .v-application--wrap { background: #121212; }
        .v-application.theme--light .v-application--wrap { background: #FFF; }
      `
        }
      }),
      h(VDialog, {
        props: {
          fullscreen: true,
          transition: 'dialog-bottom-transition',
          value: this.value,
          ...this.$attrs
        },
        on: {
          input: value => this.$emit('input', value)
        }
      }, this.$slots.default)
    ])
  }
}
</script>

<style lang="scss">
.v-application--wrap {
  border-radius: 0;
  transition-duration: 0.5s;
  transition-property: border-radius, transform;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

html.popover--visible {
  .v-application--wrap {
    border-radius: 10px;
    transform: scale(0.97);
  }

  .v-dialog--fullscreen {
    .v-sheet.v-card {
      border-radius: 10.5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    margin-top: $popover-fullscreen-margin;
    max-height: $popover-fullscreen-content;
  }
}

</style>
