<script>
export default {
  name: 'CupertinoGrid',
  render: function (h) {
    return h('div', {
      class: 'cupertino__grid'
    }, [
      h('div', {
        class: 'cupertino__grid__content'
      }, this.$slots.default)
    ])
  }
}
</script>

<style lang="scss">
.cupertino__grid {
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__content {
    $grid-dimension: 356px;

    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: $grid-dimension;
    position: absolute;
    top: 0;
    transform: scale(var(--cupertino-grid-scale)) translateZ(0);
    transform-origin: top left;
    width: $grid-dimension;
    will-change: transform;
  }
}
</style>
