<script>
export default {
  name: 'SearchForm',
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <VTextField
    label="Search by Vehicle or Customer"
    prepend-inner-icon="$search"
    clearable
    hide-details
    solo
    flat
    class="v-text-field--search"
    :dense="dense"
  />
</template>

<style lang="scss">
$line-height-dense: 16px;

.v-text-field--search.v-input--dense {
  .v-input__slot {
    border-radius: 8px;

    .v-icon__svg {
      height: 18px;
      width: 18px;
    }
  }

  .v-text-field__slot {
    .v-label,
    input {
      font-size: 14px;
      line-height: $line-height-dense;
    }

    .v-label {
      top: calc(50% - calc(#{$line-height-dense} / 2));
    }
  }
}

.theme--light.v-text-field--search {
  .v-input__slot {
    background-color: #F1F3F4 !important;
  }
}
</style>
