<script>
import CarfaxWidgetBase from '@maxsystems/xplat/core/widgets/carfax/CarfaxWidget.base.vue'

import CarfaxLogo from './CarfaxLogo'
import { CupertinoWidget } from '~/routes/VDP/components/Cupertino'
export default {
  components: {
    CarfaxLogo,
    CupertinoWidget
  },
  mixins: [CarfaxWidgetBase],
  methods: {
    checkMustLogin () {
      if (!this.mustLogin) return
      window.open(this.loginURL, '_blank', 'noopener,noreferrer')
    }
  }
}
</script>

<template>
  <CupertinoWidget
    class="cupertino__widget--carfax"
    :type="mustLogin ? 'warning' : undefined"
    :to="
      !mustLogin
        ? {
          name: 'vdp.widget.carfax.detail',
          params: { id: $route.params.id, widgetId: 'carfax' },
        }
        : undefined
    "
    @click="checkMustLogin"
  >
    <CarfaxLogo class="carfax__logo" />
    <span
      v-if="isLoading || hasReport"
      class="carfax__pillars"
    >
      <VSkeletonLoader
        v-for="({ icon, text }, i) in fourPillars"
        :key="`${i}-${icon.split('/').pop()}`"
        class="pillar"
        :loading="!icon"
        type="image"
      >
        <img
          :src="icon"
          :alt="text"
          :title="text"
          class="pillar__icon"
        >
      </VSkeletonLoader>
    </span>
    <span
      v-else
      class="pillar__label"
    >{{
      mustLogin ? "Login Required" : "No Report Found"
    }}</span>
  </CupertinoWidget>
</template>

<style lang="scss">
.cupertino__widget--carfax {
  color: #7B7B7D;
  cursor: pointer;
  font-size: 14px;

  &.cupertino__widget--warning {
    color: #D57A2D;
    font-weight: 500;
    text-decoration: none;
  }

  .cupertino__widget__content {
    align-items: center;
    display: flex !important;
    flex-flow: column;
    justify-content: center;
  }

  .carfax {
    &__logo {
      margin: 16px 0 8px 3px;
    }

    &__pillars {
      display: flex;
      flex-flow: row wrap;
      gap: 18px;
      justify-content: center;
      margin: 8px 0 16px;
      width: 60%;
    }
  }

  .pillar {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-flow: column;
    height: 40px;
    justify-content: center;
    width: 40px;

    &__label {
      margin-bottom: 16px;
    }

    &__icon {
      height: auto;
      width: 100%;
    }

    .v-skeleton-loader__image {
      width: 100%;
    }
  }
}

.theme--dark {
  .cupertino__widget--carfax {
    &.cupertino__widget--warning {
      color: #D29922;
    }

    .v-skeleton-loader__image {
      opacity: 0.8;
    }
  }
}
</style>
