<script>
import { phone } from '@maxsystems/ui/vue/filters'

import PopoverArrow from './PopoverArrow'

export default {
  filters: {
    phone
  },
  components: {
    PopoverArrow
  },
  inject: ['authData'],
  data: () => ({
    techSupportPhone: '8773785665'
  }),
  computed: {
    accountManager () {
      return this.authData.dealer.accountManager?.profile || {}
    }
  }
}
</script>

<template>
  <VSheet class="v-sheet--popover">
    <PopoverArrow />
    <div class="v-sheet--popover__contents">
      <big class="mb-2">
        Technical Support
      </big>
      <a :href="`tel:${techSupportPhone}`">
        {{ techSupportPhone | phone }}
      </a>
      <br>
      <a href="mailto:helpdesk@maxdigital.com">
        helpdesk@maxdigital.com
      </a>
    </div>
    <VDivider />
    <div class="v-sheet--popover__contents">
      <big class="mb-2">
        Training &amp; Questions
      </big>
      {{ accountManager.name }}
      <br v-if="accountManager.phone">
      <a :href="`tel:${accountManager.phone}`">
        {{ accountManager.phone | phone }}
      </a>
      <br>
      <a :href="`mailto:${accountManager.email}`">
        {{ accountManager.email }}
      </a>
    </div>
  </VSheet>
</template>

<style lang="scss">
.v-menu__content--popover {
  box-shadow: unset;
  contain: unset;
  overflow: visible;

  .v-sheet--popover {
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    width: 216px;

    &.theme--dark {
      background: #1E1E1E;
      box-shadow: 0 0 15px rgba(0 0 0 / 25%), 0 0 0 1px rgb(255 255 255 / 10%);
    }

    &.theme--light {
      background: #FFF;
      box-shadow: 0 0 15px rgb(0 0 0 / 15%), 0 0 0 1px rgb(0 0 0 / 10%);
    }

    &__contents {
      padding: 16px 12px;
    }

    big {
      display: block;
      font-size: 14px;
      line-height: 18px;
    }

    a {
      color: inherit;
      opacity: 0.7;
      text-decoration: none;
    }
  }

  .popover__arrow {
    position: absolute;
    transform: rotate(180deg) translateY(13px) translateX(-32px);
  }
}
</style>
