<script>
import widgets from '@maxsystems/xplat/web/widgets'

import PopoverDialog from '~/components/PopoverDialog.vue'

export default {
  name: 'WidgetDetail',
  components: {
    PopoverDialog
  },
  inject: ['uiState', 'theme'],
  data: () => ({
    width: window.innerWidth - 380
  }),
  computed: {
    sidebar: ({ uiState }) => uiState.sidebar,
    widget: ({ uiState }) => uiState.widget,
    widgetConfig: ({ widget }) => widgets[widget.current],
    widgetTheme: ({ theme, widgetConfig }) =>
      widgetConfig.theme[theme.isDark ? 'dark' : 'light']
  },

  watch: {
    '$route.params.widgetId': {
      handler (newWidgetId, oldWidgetId) {
        if (!newWidgetId) {
          // no widget closes the widget pane
          this.widget.close()

          // if we aren't moving from widget to "no widget"
          // open the sidebar. This opens the srp when navigating
          // directly to an appraisal Id
          if (!oldWidgetId) this.sidebar.open()
          return
        }

        this.widget.open(newWidgetId)
      },
      immediate: true
    },

    'uiState.widget.isOpen' (isOpen) {
      if (isOpen) return
      this.goBackToVDP()
    }
  },

  mounted () {
    // we need to compute width through JS since Vuetify
    // doesn't support calc(100vw - 380px) in VNavigationDrawer width
    // cf.: https://github.com/vuetifyjs/vuetify/blob/386a8343a38f34070993911a69777c5ec8c11331/packages/vuetify/src/components/VNavigationDrawer/VNavigationDrawer.ts#L442-L444
    this.$observer = new ResizeObserver(this.onResize)
    this.$observer.observe(window.document.body)
  },
  beforeDestroy () {
    this.$observer.disconnect()
  },

  methods: {
    onResize () {
      this.width = window.innerWidth - 380
    },

    goBackToVDP () {
      this.$router.push({ name: 'vdp', params: { id: this.$route.params.id } })
    }
  }
}
</script>

<template>
  <div class="widget-detail">
    <VNavigationDrawer
      v-if="!$vuetify.breakpoint.mobile"
      app
      class="v-navigation-drawer--widget"
      clipped
      stateless
      right
      :value="widget.isOpen"
      :width="width"
    >
      <VCard
        v-if="widget.isOpen"
        class="widget-detail__top-bar d-flex align-center"
        :color="widgetTheme.primary"
        flat
      >
        <div class="col-4">
          <VBtn
            color="white"
            large
            icon
            @click="widget.close"
          >
            <VIcon>$arrowBack</VIcon>
          </VBtn>
        </div>
        <VCardTitle class="col-4 justify-center">
          <component
            :is="widgetConfig.logo"
            class="widget-detail__logo"
          />
        </VCardTitle>
        <VSpacer class="col-4" />
      </VCard>
      <div class="widget-detail--content">
        <router-view name="detail" />
      </div>
    </VNavigationDrawer>
    <PopoverDialog
      v-if="$vuetify.breakpoint.mobile"
      content-class="popover-dialog--widget"
      :value="widget.isOpen"
      @input="widget.close"
    >
      <VCard class="d-flex">
        <VContainer
          class="pa-0"
          fluid
        >
          <VCard
            v-if="widget.isOpen"
            class="widget-detail__top-bar"
            :color="widgetTheme.primary"
            flat
          >
            <VContainer
              fluid
              class="d-flex fill-height"
            >
              <VRow>
                <VCol
                  class="d-flex justify-start"
                  cols="4"
                >
                  <VBtn
                    v-if="widgetConfig.topBar.hasCancel"
                    color="white"
                    exact
                    icon
                    @click="widget.close"
                  >
                    <VIcon>$arrowBack</VIcon>
                  </VBtn>
                </VCol>
                <VCol
                  class="d-flex justify-center align-center"
                  cols="4"
                >
                  <component
                    :is="widgetConfig.logo"
                    class="widget-detail__logo"
                  />
                </VCol>
                <VCol
                  class="d-flex justify-end"
                  cols="4"
                >
                  <VBtn
                    class="widget-detail__done"
                    color="white"
                    text
                    @click="widget.close"
                  >
                    Done
                  </VBtn>
                </VCol>
              </VRow>
            </VContainer>
          </VCard>
          <div class="widget-detail--content">
            <router-view name="detail" />
          </div>
        </VContainer>
      </VCard>
    </PopoverDialog>
  </div>
</template>

<style lang="scss">
$header-height-desktop: 78px;
$header-height-mobile: 64px;

.popover-dialog--widget,
.v-navigation-drawer--widget {
  .widget-detail__top-bar {
    border-radius: 0;
  }

  .widget-detail__logo {
    height: 32px;
    width: auto;
  }
}

/**
 * Force the color on the container only when
 * integrated with the header layout, preventing
 * leaking the background color to the Offer page
 */
.v-toolbar + .v-main {
  background: #F2F2F6 !important;
}

.theme--dark .v-main {
  background: #121212 !important;
}

.v-navigation-drawer--widget {
  border-bottom-left-radius: 10.5px;
  border-top-left-radius: 10.5px;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%), 0 0 0 1px rgb(0 0 0 / 10%);

  .v-navigation-drawer__border {
    display: none;
  }

  .widget-detail__top-bar {
    .v-card__title {
      height: $header-height-desktop;
    }
  }

  .widget-detail--content {
    height: calc(100% - #{$header-height-desktop});
    overflow-y: auto;
  }
}

.popover-dialog--widget {
  .widget-detail__top-bar {
    height: $header-height-mobile;

    .v-btn {
      .v-btn__content {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }

  .widget-detail--content {
    height:
      calc(
        100vh - #{$popover-fullscreen-margin} - #{$header-height-mobile}
      );
    overflow-y: auto;
  }
}
</style>
