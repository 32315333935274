<script>
import ToolbarBtn from './ToolbarBtn.vue'

export default {
  name: 'WindowStickerBtn',
  components: {
    ToolbarBtn
  },
  inject: ['appraisalData'],
  computed: {
    windowSticker: ({ appraisalData }) => appraisalData.node?.vehicle?.windowSticker,
    hasWindowSticker: ({ windowSticker }) => Boolean(windowSticker),
    tooltipText ({ windowSticker }) {
      if (windowSticker) return 'OEM Window Sticker'
      if (windowSticker === null) return 'No OEM Window Sticker Found'
      return 'Fetching Window Sticker'
    }
  },
  watch: {
    'appraisalData.id': {
      handler () {
        if (this.hasWindowSticker) return
        this.getWindowSticker()
      },
      immediate: true
    }
  },
  methods: {
    async getWindowSticker () {
      await this.appraisalData.query('vehicle { windowSticker }')
    }
  }
}
</script>

<template>
  <VTooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <ToolbarBtn
          :disabled="!hasWindowSticker"
          :to="hasWindowSticker ? {
            name: 'vdp.widget.window-sticker.detail',
            params: { id: $route.params.id, widgetId: 'window-sticker' }
          } : undefined"
        >
          <VIcon v-text="'M16.433 6.627a.555.555 0 0 1-.419.168H8.347a.599.599 0 0 1-.437-.168.57.57 0 0 1-.162-.41.54.54 0 0 1 .162-.402.599.599 0 0 1 .437-.168h7.667c.172 0 .311.056.419.168.114.106.171.24.171.401a.553.553 0 0 1-.171.41Zm-3.168 2.705a.555.555 0 0 1-.418.168h-4.5a.599.599 0 0 1-.437-.168.57.57 0 0 1-.162-.41c0-.162.054-.296.162-.401a.599.599 0 0 1 .437-.168h4.5c.17 0 .31.056.418.168a.521.521 0 0 1 .171.4.553.553 0 0 1-.17.411Zm3.52 8.162c0 .828-.422 1.241-1.265 1.241H8.49c-.4 0-.714-.102-.942-.308-.222-.211-.333-.522-.333-.933v-5.186c0-.41.111-.722.333-.933.228-.211.542-.317.942-.317h7.03c.843 0 1.265.417 1.265 1.25v5.186Z M4.732 21.272C4.244 20.787 4 20.057 4 19.08V4.93c0-.971.244-1.702.732-2.193C5.227 2.246 5.966 2 6.95 2h10.1c.983 0 1.719.246 2.207.737.495.491.742 1.222.742 2.192V19.08c0 .977-.247 1.707-.742 2.192-.488.486-1.224.728-2.207.728H6.95c-.983 0-1.722-.242-2.217-.728Zm1.17-17.388c-.247.25-.37.607-.37 1.073v14.095c0 .467.123.824.37 1.073.254.249.628.373 1.123.373h9.95c.495 0 .866-.124 1.113-.373.254-.249.38-.606.38-1.073V4.957c0-.466-.126-.824-.38-1.073-.247-.255-.618-.382-1.113-.382h-9.95c-.495 0-.869.127-1.123.382Z'" />
        </ToolbarBtn>
      </div>
    </template>
    <span v-text="tooltipText" />
  </VTooltip>
</template>
