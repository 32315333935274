<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'CalculatorTargetsDialog',
  props: {
    current: {
      type: String,
      default: 'recon'
    },
    recon: {
      type: Number,
      default: 0
    },
    profit: {
      type: Number,
      default: 0
    },
    other: {
      type: Number,
      default: 0
    }
  },
  data: ({ recon, profit, other }) => ({
    // TODO: Need update targets from backend.
    select: 'Other',
    choices: ['Other'],
    targets: {
      recon,
      profit,
      other
    },
    formatterOptions: {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }
  }),
  methods: {
    saveTargets () {
      this.$emit('close', {
        recon: parseInt(this.targets.recon),
        profit: parseInt(this.targets.profit),
        other: parseInt(this.targets.other)
      })
    }
  }
})
</script>
