<script>
export const breakpoints = {
  sm: 400,
  md: 900,
  lg: 1200,
  xl: 1700,
  xxl: 2100
}

export default {
  name: 'CupertinoSpringboard',
  data: () => ({
    breakpoints,
    // grid element reference to get offsetWidth from
    gridElement: null,
    // width of Springboard
    width: null
  }),
  computed: {
    breakpoint: ({ breakpoints, width }) => {
      const matches = Object.keys(breakpoints)
        .filter(breakpoint => width > breakpoints[breakpoint])
      return matches[matches.length - 1]
    }
  },
  mounted () {
    this.width = this.$el.offsetWidth
    this.gridElement = this.$el.querySelector('.cupertino__grid')
    requestAnimationFrame(this.setGridScale) // prevents FOUC on first load
    this.$observer = new ResizeObserver(this.onResize)
    this.$observer.observe(this.$el)
  },
  beforeDestroy () {
    this.$observer.disconnect()
  },
  methods: {
    onResize () {
      // prevent ResizeObserver loop limit error and increase performance
      requestAnimationFrame(this.setDimensions)
    },
    setDimensions () {
      this.width = this.$el.offsetWidth
      this.setGridScale()
    },
    setGridScale () {
      this.$el.style.setProperty('--cupertino-grid-scale', this.gridElement.offsetWidth / 356)
    }
  },
  render (h) {
    const { breakpoint } = this
    return h('div', {
      class: [
        'cupertino__springboard',
        {
          [`cupertino__springboard--${breakpoint}`]: breakpoint
        }
      ]
    }, this.$slots.default)
  }

}
</script>

<style lang="scss">
.cupertino__springboard {
  $cupertino-springboard-breakpoints: sm, md, lg, xl, xxl;

  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  width: 100%;

  @each $size in $cupertino-springboard-breakpoints {
    &--#{$size} {
      // stylelint-disable-next-line scss/no-global-function-names, function-no-unknown
      grid-template-columns: repeat(index($cupertino-springboard-breakpoints, $size) + 1, 1fr);
    }
  }
}
</style>
