<script>
import Themeable from 'vuetify/lib/mixins/themeable'

import { InputNumeric } from '@maxsystems/ui/elements'
import AppraisalCalculatorBase from '@maxsystems/xplat/core/routes/VDP/components/AppraisalCalculator.base.vue'

import { CalculatorTargetsDialog } from './CalculatorTargetsDialog.vue'

export default Themeable.extend({
  components: {
    InputNumeric
  },
  mixins: [AppraisalCalculatorBase],
  inject: ['dataSource'],
  methods: {
    updateAppraisalValue () {
      if (this.value !== this.appraisalPrice) {
        this.dataSource.updateAppraisalValue(this.id, this.appraisalPrice)
      }
    },
    blurSalesPrice () {
      this.setMinimumSalesPrice()
      this.updateAppraisalValue()
    },
    /**
     * Pass the selected input and targets values to CalculatorTargetDialog.
     * Update the return targets value from the dialog.
     * @param {String} current - Current additional values, either recon, profit or other.
     */
    async inputToFocus (current) {
      const targets = await CalculatorTargetsDialog.open({
        current,
        ...this.internalTargets
      })
      if (!targets) return
      this.updateTargets(targets)
    }
  }
})
</script>

<template>
  <VContainer
    class="appraisal-calculator"
    fluid
    :class="{ ...themeClasses, 'appraisal-calculator--dense': dense }"
  >
    <VRow no-gutters>
      <VCol class="appraisal d-flex align-center">
        <InputNumeric
          ref="appraisal"
          v-model="appraisalPrice"
          dense
          hide-details
          outlined
          :options="formatterOptions"
          :class="{
            'text-left': dense,
            'v-text-field--active-input': activeInput === 'appraisal',
          }"
          v-bind="{ [dense ? 'suffix' : 'prefix']: 'Appraisal' }"
          maxlength="9"
          @focus="activeInput = 'appraisal'"
          @blur="updateAppraisalValue"
        />
      </VCol>
      <VCol class="sales-price d-flex align-center">
        <InputNumeric
          ref="sales"
          v-model="salesPrice"
          dense
          hide-details
          outlined
          :options="formatterOptions"
          :class="{ 'v-text-field--active-input': activeInput === 'sales' }"
          v-bind="{ [dense ? 'prefix' : 'suffix']: 'Sales Price' }"
          maxlength="9"
          @focus="activeInput = 'sales'"
          @blur="blurSalesPrice"
        />
      </VCol>
      <VCol class="maths px-4 d-flex">
        <span
          role="math"
          aria-label="+"
          class="divider"
        >
          <VIcon
            :size="26"
            v-text="'$plus'"
          />
        </span>

        <div
          class="targets d-flex flex-nowrap justify-space-around grey--text text--darken-1 text-caption align-center flex-grow-1"
        >
          <template
            v-for="({ label, price, customClasses }, index) in additionalValues"
          >
            <span
              v-if="index"
              :key="`${label}-divider`"
              role="math"
              aria-label="+"
              class="divider divider--inner"
            >
              <VIcon
                :size="18"
                v-text="'$plus'"
              />
            </span>
            <VBtn
              v-if="label"
              :ref="`${label.toLowerCase()}-button`"
              :key="`${label}-label`"
              text
              height="100%"
              color="var(--v-grey-lighten)"
              @click="inputToFocus(label.toLowerCase())"
            >
              <span class="text-center text-caption">
                <strong
                  :class="customClasses"
                  class="text-body-2 font-weight-bold"
                >{{ price }}</strong><br>
                {{ label }}
              </span>
            </VBtn>
          </template>
        </div>
        <span
          role="math"
          aria-label="="
          class="divider"
        >
          <VIcon
            :size="26"
            v-text="'$equal'"
          />
        </span>
      </VCol>
    </VRow>
  </VContainer>
</template>

<style lang="scss">
.appraisal-calculator {
  background: #FFF;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
  border-top: 1px solid rgb(0 0 0 / 12%);
  padding: 0 1rem;

  .col.appraisal,
  .col.sales-price {
    flex: 1 0 25%;
    max-width: 25%;
  }

  .col.appraisal {
    order: 1;
  }

  .col.sales-price {
    order: 3;
  }

  .maths {
    flex: 1 0 50%;
    flex-wrap: nowrap;
    justify-content: space-around;
    max-width: 50%;
    order: 2;

    .targets {
      padding: 1rem;

      .v-btn {
        min-width: 0;
        padding: 0;
      }
    }
  }

  .text-caption {
    color: #6F7070;
  }

  .v-text-field {
    border-radius: 8px;
    text-align: right;

    input {
      font-size: 20px;
      padding: 0 0.5rem !important;
      text-align: inherit;
    }

    .v-input__slot {
      background: #F1F3F4;
      padding: 0 !important;
    }

    &__prefix,
    &__suffix {
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      max-height: unset;
      padding: 0 0.75rem;
      transition-property: color, background-color;
    }

    &__prefix {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      margin-right: 0.25rem;
    }

    &__suffix {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      margin-left: 0.25rem;
    }

    fieldset {
      border-width: 1px;
    }

    &.v-input--is-focused {
      .v-text-field__prefix,
      .v-text-field__suffix {
        background-color: var(--v-primary-base);
        color: #FFF;
      }

      .v-input__slot {
        background-color: #FFF;
      }
    }

    &.v-text-field--active-input:not(:focus) {
      fieldset {
        color: var(--v-primary-base) !important;
      }
    }
  }

  .divider {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;

    &::before {
      background-color: rgb(0 0 0 / 12%);
      content: "";
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
    }

    .v-icon {
      background-color: #FFF;
      border: 1px solid rgb(0 0 0 / 12%);
      border-radius: 100%;
      color: rgb(0 0 0 / 24%);
    }
  }

  &.theme--dark {
    background-color: #272727;
    border-color: rgb(255 255 255 / 12%);

    .divider {
      &::before {
        background-color: rgb(255 255 255 / 12%);
      }

      .v-icon {
        background-color: #414141;
        border-color: rgb(255 255 255 / 12%);
        color: rgb(255 255 255 / 70%);
      }
    }

    .v-text-field {
      .v-input__slot {
        background: #1E1E1E;
      }

      &.v-input--is-focused {
        .v-input__slot {
          background-color: #272727;
        }
      }
    }
  }

  &--dense {
    padding: 0.75rem 0.5rem;

    .col.appraisal {
      flex: 1 0 50%;
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .col.sales-price {
      flex: 1 0 50%;
      max-width: 50%;
      order: 2;
      padding-left: 0.25rem;
    }

    .maths {
      flex: 1 0 100%;
      max-width: 100%;
      order: 3;

      .targets {
        padding: 0;
        padding-top: 1rem;

        .v-btn {
          min-width: 0;
          padding: 0;
        }
      }
    }

    .divider:not(.divider--inner) {
      align-items: end;

      &::before {
        height: 50%;
      }

      .v-icon {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
