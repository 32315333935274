import Vue from 'vue'

import { request } from '@maxsystems/client'

import { merge } from '../../data-source'

/**
 * Function that returns a Vue component for querying and updating Appraisal data from a data source.
 *
 * @param {Object} dataSource - Main data source object
 */
export const getAppraisalData = dataSource => Vue.extend({
  data: () => ({
    id: null
  }),

  computed: {
    node () {
      return dataSource.nodes[this.id]
    }
  },

  methods: {
    /**
     * Query for Appraisal details through GraphQL and update the cache with results if successful.
     * @param {String} fragment - A GraphQL fragment with fields.
     * @param {Object} variables - Optional variables if needed by the field resolvers.
     */
    async query (fragment, variables = {}) {
      const { data, errors } = await request(
        `query GetAppraisalDetails ($id: ID!) { appraisal (id: $id) { id ${fragment} } }`,
        { id: this.id, ...variables }
      )

      if (data?.appraisal) this.update(data.appraisal.id, data.appraisal)
      return { data, errors }
    },

    /**
     * Update the internal cache for this Appraisal document. Performs a deep merge.
     * @param {Object} newValue - New or updated Appraisal fields (pass `false` to purge cache)
     */
    async update (id, newValue) {
      if (newValue === false) return Vue.delete(dataSource.nodes, id)
      Vue.set(dataSource.nodes, id, merge(dataSource.nodes[id] || {}, newValue))
    }
  }
})
