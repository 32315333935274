<script>
import CarfaxWidgetDetailBase from '@maxsystems/xplat/core/widgets/carfax/CarfaxWidgetDetail.base.vue'

import IframeWrapper from '~/routes/VDP/components/IframeWrapper'
export default {
  components: {
    IframeWrapper
  },
  mixins: [CarfaxWidgetDetailBase]
}
</script>

<template>
  <div class="carfax-widget-detail fill-height">
    <IframeWrapper
      v-if="!mustLogin && (hasReport || loading)"
      :src="url"
      :loading.sync="loading"
      title="CARFAX Detail"
    />

    <VCard
      v-if="mustLogin"
      class="d-flex align-center justify-center fill-height"
      tile
    >
      <a
        :href="loginURL"
        target="_blank"
        rel="noopener noreferrer"
      >
        Login Required
      </a>
    </VCard>

    <VCard
      v-if="!mustLogin && !hasReport && !loading"
      class="d-flex align-center justify-center fill-height"
      tile
    >
      No Report Found
    </VCard>
  </div>
</template>
