import Vue from 'vue'

import { authData, withQuery } from '@maxsystems/client'

import { getAppraisal, getAppraisals, updateAppraisals, createAppraisal } from './queries'

const isObject = obj => obj !== null && typeof obj === 'object'

// Pulled from `@nautilus/config/merge` but replacing Array vs `.concat`
const merge = (...sources) => sources.reduce((result, toMerge) => {
  for (const [key, newValue] of Object.entries(toMerge)) {
    const oldValue = result[key]

    if (isObject(oldValue) && isObject(newValue)) {
      result[key] = merge(oldValue, newValue)
    } else {
      result[key] = newValue
    }
  }

  return result
}, {})

const dataSource = Vue.extend({
  data: () => ({
    totalCount: 0,
    nodes: {},
    queries: {
      createAppraisal: withQuery(createAppraisal),
      getAppraisals: withQuery(getAppraisals),
      updateAppraisals: withQuery(updateAppraisals)
    }
  }),

  computed: {
    appraisals: ({ nodes }) => Object.values(nodes)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    dealerId: () => authData.dealer.id,
    new: ({ appraisals }) => appraisals.filter(({ value }) => value === null)
  },

  methods: {
    /**
     * Add an Appraisal document to the internal cache.
     * @param {Object} data - New appraisal data payload
     */
    add (data) {
      // @TODO: Fix bug where count isn't being updated upon add.
      // See: https://maxdigital.atlassian.net/browse/CX-1411
      Vue.set(this.nodes, data.id, data)
    },

    /**
     * Given a VIN, creates an appraisal record.
     * @param {String} vin - Vehicle Identification Number
     * @returns {Object} Newly created appraisal object
     */
    async createAppraisal (vin) {
      const { data, errors } = await this.queries.createAppraisal.fetch({ vin, dealerId: this.dealerId })
      if (errors) return this.handleErrors(errors)

      const appraisal = data.createAppraisal.appraisal
      this.add(appraisal)
      return appraisal
    },

    init () {
      this.fetchAppraisals()
    },

    async fetchAppraisals (after = '', first = 25) {
      /**
       * When loading an offer directly, the dealer is undefined
       * and there is no need to load all appraisals
       */
      const buid = authData.dealer?.id
      if (!buid) return

      const { data } = await this.queries.getAppraisals.fetch({ buid, after, first })
      const { edges, pageInfo: { endCursor, hasNextPage }, totalCount } = data.dealer.appraisals

      this.totalCount = totalCount
      for (const { node, node: { id } } of edges) {
        Vue.set(this.nodes, id, merge(this.nodes[id] || {}, node))
      }

      if (hasNextPage) await this.fetchAppraisals(endCursor, first)
    },
    /**
     * Takes an appraisal id and an amount, then updates the appraisal's valuation to that amount
     * @param {string} id - The ID of the Appraisal document to update.
     * @param {number} amount - The new valuation amount of the Appraisal
     */
    async updateAppraisalValue (id, amount) {
      const input = { updateValuation: [{ id, amount }] }
      const { errors, data } = await this.queries.updateAppraisals.fetch({ input })
      if (errors) return this.handleErrors(errors)
      const { value } = data.updateAppraisal[0].appraisal
      this.update(id, { value })
    },
    /**
     * Takes an array of records and updates the corresponding appraisals' dispositions based on ids, then
     * updates the internal caches of those appraisals.
     * @param {{id, disposition}[]} records - arrays of ids and dispostions to update.
     */
    async updateAppraisalDisposition (records) {
      const { errors, data } = await this.queries.updateAppraisals.fetch({ input: { updateDisposition: records } })
      if (errors) return this.handleErrors(errors)
      this.updateMany(data.updateAppraisal)
    },

    /**
     * Takes an array of errors and throws the first error message.
     * @param {Error[]} errors - array of Errors
     */
    handleErrors (errors) {
      if (!errors) return
      throw new Error(errors[0].message)
    },

    /**
    * Update the internal cache for this Appraisal document. Performs a deep merge.
    * @param {string} id - The ID of the Appraisal document to update.
    * @param {Object} newValue - New or updated Appraisal fields (pass `false` to purge cache)
    */
    async update (id, newValue) {
      if (newValue === false) return Vue.delete(this.nodes, id)
      Vue.set(this.nodes, id, merge(this.nodes[id] || {}, newValue))
    },

    /**
    * Takes the ids of pass updatedRecords and updates
    * the relevant records by id and appraisal fields if they exist
    *
    * @param { {id, appraisal}[] } updatedRecords
    */
    updateMany (updatedRecords) {
      updatedRecords.forEach(({ id, appraisal }) => this.update(id, appraisal))
    }
  }
})

export default dataSource

export {
  merge,
  dataSource,
  getAppraisal,
  getAppraisals,
  updateAppraisals
}
