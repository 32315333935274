<script>
export default {
  name: 'DealerSwitcher',
  props: {
    dealerNodes: {
      type: Array,
      required: true
    },
    currentDealer: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    value: null
  }),
  computed: {
    dealers () {
      return this.dealerNodes
        .map(({ id, name }) => ({ id, name }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
    }
  },
  created () {
    this.value = this.currentDealer
  },
  methods: {
    select (dealer) {
      if (!dealer || this.currentDealer.id === dealer.id) return
      this.loading = true
      this.$auth.switchDealer(dealer.id)
    }
  }
}
</script>
