<script>
import IframeWrapper from '~/routes/VDP/components/IframeWrapper'

export default {
  name: 'WindowStickerWidgetDetail',
  components: {
    IframeWrapper
  },
  inject: ['appraisalData'],
  data: () => ({
    loading: true
  }),
  computed: {
    windowSticker: ({ appraisalData }) => appraisalData.node?.vehicle?.windowSticker
  }
}
</script>

<template>
  <div class="window-sticker-widget-detail fill-height">
    <VCard
      v-if="windowSticker === null"
      class="d-flex align-center justify-center fill-height"
      tile
    >
      No Window Sticker Found
    </VCard>
    <IframeWrapper
      v-else
      :src="windowSticker"
      :loading.sync="loading"
      title="Window Sticker"
    />
  </div>
</template>
