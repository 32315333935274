<script>
export default {
  name: 'IframeWrapper',
  props: {
    src: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      require: true
    },
    title: {
      type: String,
      required: true
    }
  },

  watch: {
    src () {
      this.$emit('update:loading', true)
    }
  }
}
</script>

<template>
  <div class="d-flex fill-height">
    <VProgressLinear
      :active="loading"
      indeterminate
      absolute
    />
    <iframe
      v-if="src"
      class="iframe-wrapper__content"
      :title="title"
      :src="src"
      @load="$emit('update:loading', false)"
    />
  </div>
</template>

<style lang="scss">
.iframe-wrapper__content {
  border: 0;
  width: 100%;
}
</style>
