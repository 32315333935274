import ACVEstimateWidget from './ACVEstimateWidget'
import ACVLogo from './ACVLogo.vue'
import ACVEstimateWidgetDetail from './detail/index.vue'

export default {
  id: 'acv',
  theme: {
    dark: { primary: '#B2410A' },
    light: { primary: '#F26522' }
  },
  logo: ACVLogo,
  topBar: { hasCancel: false },
  widget: ACVEstimateWidget,
  detail: ACVEstimateWidgetDetail
}
