<script>
import NewAppraisalDialogBase from '@maxsystems/xplat/core/routes/SRP/components/NewAppraisalDialog/NewAppraisalDialog.base.vue'

import DialogWrapper from '~/components/DialogWrapper'

export default {
  components: {
    DialogWrapper
  },
  mixins: [NewAppraisalDialogBase],
  inject: ['dataSource'],
  methods: {
    lazyValidate (value) {
      if (!value) return

      if (value.length < this.vinLength) this.$refs.form.resetValidation()
      this.vin = value
      this.error = ''
    },
    resetForm () {
      this.vin = ''
      this.error = ''
      this.$refs.form.reset()
    },
    async submit () {
      this.error = ''
      if (!this.$refs.form.validate()) return

      try {
        const createdAppraisal = await this.dataSource.createAppraisal(this.vin)
        this.resetForm()
        this.$emit('close', createdAppraisal.id)
      } catch {
        // Only show the error if the dialog is still open
        if (!this.vin) return
        this.error = 'Problem creating appraisal. Please try again.'
      }
    },
    close () {
      if (this.isCreatePending) {
        this.dataSource.queries.createAppraisal.abort()
      }
      this.resetForm()
      this.$emit('close')
    }
  }
}
</script>

<template>
  <DialogWrapper
    title="New Appraisal"
    class="new-appraisal"
    :pending="isCreatePending"
    v-on="$listeners"
    @submit="submit"
    @close="close"
  >
    <VTabs
      v-model="step"
      hide-slider
      show-arrows
      class="ml-n3 ml-xs-n3 mb-4"
    >
      <VTab
        v-for="({ label, active }, i) in steps"
        :key="i"
        :ripple="false"
        :disabled="!active"
        class="text-capitalize font-weight-regular px-4"
      >
        {{ label }}
      </VTab>
    </VTabs>
    <VTabsItems v-model="step">
      <VTabItem
        key="0"
        class="new-appraisal__vin"
      >
        <VForm ref="form">
          <VTextField
            ref="vin"
            aria-label="VIN"
            :maxlength="vinLength"
            class="rounded-lg"
            spellcheck="false"
            :value="vin"
            :rules="rules.vin"
            :height="48"
            :error-messages="error"
            :hint="details"
            autofocus
            filled
            rounded
            dense
            @input="lazyValidate"
            @keydown.enter.prevent="submit"
          />
        </VForm>
      </VTabItem>
    </VTabsItems>
  </DialogWrapper>
</template>

<style lang="scss">
.new-appraisal {
  .v-tab {
    font-size: 18px;
    letter-spacing: 0;
    min-width: auto;

    &.v-tab--active {
      font-weight: 500 !important;

      &::before {
        border-radius: 4px;
      }
    }
  }

  &__vin {
    max-width: 330px;

    .v-input__slot {
      padding: 0 12px !important;

      input {
        text-transform: uppercase;
      }
    }

    .v-messages.primary--text,
    .v-input.error--text input {
      color: var(--v-error-base) !important;
    }

    .v-text-field__details {
      min-height: 22px;
    }

    .v-messages__message {
      line-height: 22px;
    }
  }
}
</style>
