import acvEstimate from './acvEstimate'
import carfax from './carfax'
import windowSticker from './window-sticker'

export default [
  acvEstimate,
  carfax,
  windowSticker
  // add more plugins here
].reduce((acc, plugin) => {
  acc[plugin.id] = plugin
  return acc
}, {})
