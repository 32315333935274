<script>

import { withQuery } from '@maxsystems/client'
import { currency } from '@maxsystems/ui/vue/filters'
import { getDisclosures } from '@maxsystems/xplat/core/data-source/queries'

import ACVLogo from './ACVLogo.vue'
import { CupertinoWidget } from '~/routes/VDP/components/Cupertino'

export default {
  name: 'ACVEstimateWidget',
  components: {
    CupertinoWidget,
    ACVLogo
  },
  filters: {
    currency
  },
  inject: ['authData', 'appraisalData'],
  data: () => ({
    valuation: null,
    query: withQuery(getDisclosures),
    isLoading: false
  }),
  computed: {
    disclosures: ({ valuation }) => (valuation?.disclosures || []).reduce((result, disclosure) => {
      result[disclosure.score].count++
      return result
    }, {
      GOOD: { key: 'good', count: 0, icon: 'M4.431 24h15.138c1.431 0 2.527-.38 3.289-1.142.761-.753 1.142-1.841 1.142-3.264V4.406c0-1.423-.38-2.51-1.142-3.264C22.096.381 21 0 19.569 0H4.431C3 0 1.904.38 1.142 1.142.381 1.895 0 2.983 0 4.406v15.188c0 1.423.38 2.51 1.142 3.264C1.904 23.619 3 24 4.431 24Zm6.264-5.724c-.285 0-.54-.058-.766-.176a2.28 2.28 0 0 1-.64-.552L6.35 14.034a2.026 2.026 0 0 1-.313-.515 1.406 1.406 0 0 1-.1-.527c0-.385.137-.712.413-.98.277-.276.603-.414.98-.414.234 0 .439.046.615.138.184.092.36.243.527.452l2.196 2.686 4.883-7.77c.335-.535.75-.803 1.243-.803.377 0 .707.126.992.377a1.2 1.2 0 0 1 .439.954c0 .159-.034.322-.1.49a2.51 2.51 0 0 1-.239.489l-5.837 8.912a1.84 1.84 0 0 1-.602.565 1.525 1.525 0 0 1-.753.188Z' },
      BAD: { key: 'bad', count: 0, icon: 'M4.43096 24H19.569C21 24 22.0962 23.6192 22.8577 22.8577C23.6192 22.1046 24 21.0167 24 19.5941V4.40586C24 2.98326 23.6192 1.8954 22.8577 1.14226C22.0962 0.380753 21 0 19.569 0H4.43096C3 0 1.90377 0.380753 1.14226 1.14226C0.380753 1.8954 0 2.98326 0 4.40586V19.5941C0 21.0167 0.380753 22.1046 1.14226 22.8577C1.90377 23.6192 3 24 4.43096 24ZM12.0879 13.8577C11.1757 13.8577 10.6946 13.4017 10.6444 12.4895L10.4435 7.05439C10.4184 6.58577 10.5565 6.20502 10.8577 5.91213C11.1674 5.61925 11.5732 5.4728 12.0753 5.4728C12.569 5.4728 12.9707 5.62343 13.2803 5.92469C13.59 6.21757 13.7364 6.59833 13.7197 7.06695L13.5063 12.477C13.4561 13.3975 12.9833 13.8577 12.0879 13.8577ZM12.0879 18.4017C11.5941 18.4017 11.1715 18.2552 10.8201 17.9623C10.477 17.6695 10.3054 17.2845 10.3054 16.8075C10.3054 16.3305 10.477 15.9456 10.8201 15.6527C11.1632 15.3598 11.5858 15.2134 12.0879 15.2134C12.59 15.2134 13.0084 15.3598 13.3431 15.6527C13.6862 15.9456 13.8577 16.3305 13.8577 16.8075C13.8577 17.2845 13.6862 17.6695 13.3431 17.9623C13.0084 18.2552 12.59 18.4017 12.0879 18.4017Z' },
      NEUTRAL: { key: 'neutral', count: 0, icon: 'M4.43096 24C3 24 1.90377 23.6192 1.14226 22.8577C0.380753 22.1046 0 21.0167 0 19.5941V4.40586C0 2.98326 0.380753 1.8954 1.14226 1.14226C1.90377 0.380753 3 0 4.43096 0H19.569C21 0 22.0962 0.380753 22.8577 1.14226C23.6192 1.8954 24 2.98326 24 4.40586V19.5941C24 21.0167 23.6192 22.1046 22.8577 22.8577C22.0962 23.6192 21 24 19.569 24H4.43096ZM13.0086 6.19933C12.6571 5.90644 12.2345 5.76 11.7408 5.76C11.2387 5.76 10.8203 5.90644 10.4856 6.19933C10.1425 6.49221 9.97091 6.87715 9.97091 7.35414C9.97091 7.83113 10.1425 8.21607 10.4856 8.50895C10.8203 8.80184 11.2387 8.94828 11.7408 8.94828C12.2429 8.94828 12.6655 8.80184 13.0086 8.50895C13.3517 8.21607 13.5232 7.83113 13.5232 7.35414C13.5232 6.87715 13.3517 6.49221 13.0086 6.19933ZM10.4856 18.2617C10.8203 18.5546 11.2387 18.701 11.7408 18.701C12.2429 18.701 12.6655 18.5546 13.0086 18.2617C13.3517 17.9688 13.5232 17.5839 13.5232 17.1069L13.5232 11.8923C13.5232 11.4153 13.3517 11.0304 13.0086 10.7375C12.6571 10.4446 12.2345 10.2982 11.7408 10.2982C11.2387 10.2982 10.8203 10.4446 10.4856 10.7375C10.1425 11.0304 9.97091 11.4153 9.97091 11.8923V17.1069C9.97091 17.5839 10.1425 17.9688 10.4856 18.2617Z' }
    })
  },
  watch: {
    'appraisalData.id': {
      handler (value) {
        this.isLoading = true
        this.getValuation(value)
      },
      immediate: true
    }
  },
  methods: {
    async getValuation (id) {
      const { data } = await this.query.fetch({ id })
      this.valuation = data?.appraisal.valuation
      this.isLoading = false
    }
  }
}
</script>

<template>
  <CupertinoWidget
    :class="['cupertino__widget--acv-estimate', {'cupertino__widget--acv-estimate-unavailable': !isLoading && !valuation}]"
    :to="{
      name: 'vdp.widget.acv.detail',
      params: { id: $route.params.id, widgetId: 'acv' }
    }"
  >
    <div class="widget-content">
      <div class="logo-header">
        <ACVLogo class="logo-header__acv-logo" />
      </div>
      <div v-if="!isLoading && !valuation">
        Estimate Unavailable
      </div>
      <template v-else>
        <div class="valuation-section">
          <VSkeletonLoader
            :loading="isLoading"
            width="124"
            height="39"
            type="image"
            class="valuation-section__value"
          >
            {{ !isLoading && valuation.value.amount | currency }}
          </VSkeletonLoader>
          <VSkeletonLoader
            :loading="isLoading"
            class="valuation-section__description"
            width="124"
            height="16"
            type="image"
          >
            Auction Estimate
          </VSkeletonLoader>
        </div>
        <div
          class="disclosures-section"
        >
          <VSkeletonLoader
            v-for="disclosure in disclosures"
            :key="disclosure.key"
            :loading="isLoading"
            :class="[
              'disclosure', `disclosure--${disclosure.key}`
            ]"
            width="24"
            height="16"
            type="image"
          >
            <VIcon
              size="11"
              v-text="disclosure.icon"
            />
            <span class="disclosure__text"> {{ !isLoading && disclosure.count }} </span>
          </VSkeletonLoader>
        </div>
      </template>
    </div>
  </CupertinoWidget>
</template>

<style lang="scss">
.cupertino__widget--acv-estimate {
  text-decoration: none;

  .widget-content {
    .logo-header {
      align-items: center;
      background-color: #F26522;
      display: flex;
      height: 48px;

      &__acv-logo {
        color: white;
        margin: 0 auto;
      }
    }

    .valuation-section {
      margin-top: 16px;
      text-align: center;

      .v-skeleton-loader {
        margin: 0 auto;
      }

      &__value {
        font-size: 31px;
        font-weight: 700;
        line-height: 39px;
      }

      &__description {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        &.v-skeleton-loader--is-loading {
          margin-top: 8px;
        }
      }
    }

    .disclosures-section {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 12px;

      .disclosure {
        svg {
          vertical-align: middle;
        }

        &__text {
          font-size: 13px;
          font-weight: 700;
        }

        &--good span {
          color: #4CAF4F;
        }

        &--bad span {
          color: #F44336;
        }

        &--neutral span {
          color: #76767B;
        }

        &.v-skeleton-loader--is-loading {
          margin-top: 4px;
        }
      }
    }
  }

  &-unavailable {
    .cupertino__widget__content {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .widget-content {
      align-items: center;
      color: black;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.7;

      .logo-header {
        align-items: center;
        background-color: unset;

        &__acv-logo {
          margin: 0 auto;

          path {
            fill: black;
          }
        }
      }
    }
  }
}

.theme--dark {
  .cupertino__widget--acv-estimate {
    .valuation-section__value {
      color: white;
    }

    &-unavailable {
      .widget-content {
        color: white;
      }

      .logo-header__acv-logo path {
        fill: white;
      }
    }
  }
}
</style>
