<script>
import AppraisalCalculator from '@maxsystems/xplat/web/routes/VDP/components/AppraisalCalculator.vue'
import HeaderVDP from '@maxsystems/xplat/web/routes/VDP/components/Header/HeaderVDP.vue'
import ACVEstimateWidget from '@maxsystems/xplat/web/widgets/acvEstimate/ACVEstimateWidget.vue'
import CarfaxWidget from '@maxsystems/xplat/web/widgets/carfax/CarfaxWidget.vue'
import PhotosWidget from '@maxsystems/xplat/web/widgets/photos'

import { CupertinoSpringboard, CupertinoGrid } from './components/Cupertino'
import NavbarVDP from './components/Navbar'

export default {
  name: 'VDP',
  components: {
    ACVEstimateWidget,
    AppraisalCalculator,
    CarfaxWidget,
    CupertinoGrid,
    CupertinoSpringboard,
    HeaderVDP,
    NavbarVDP,
    PhotosWidget
  },
  inject: ['dataSource', 'appraisalData', 'uiState'],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    $observer: null,
    // width of the container
    width: undefined
  }),
  computed: {
    /**  Small to medium screen with floating sidebar */
    isMobileBreakpoint: ({ width }) =>
      window.innerWidth <= width && width <= 900,
    /**
     * Large screen with open drawer widget,
     * 380 is the shrinked VDP width defined on WidgetDetail.onResize
     */
    isShrinked: ({ width }) => window.innerWidth > width && width === 380,
    ymmt: vm => vm.appraisalData.node?.vehicle?.ymmt,
    value: vm => vm.appraisalData.node?.value?.amount || 0,
    pending: ({ dataSource }) => dataSource.queries.getAppraisals.isPending()
  },
  watch: {
    id: {
      handler (newValue) {
        this.appraisalData.id = newValue

        // if an appraisal id is passed we let WidgetDetail.vue handle the sidebar opening
        if (this.id) return
        this.uiState.sidebar.open()
      },
      immediate: true
    }
  },
  mounted () {
    this.width = this.$el.offsetWidth
    this.$observer = new ResizeObserver(this.onResize)
    this.$observer.observe(this.$el)
  },
  beforeDestroy () {
    this.$observer.disconnect()
  },
  methods: {
    onResize ([{ target }]) {
      this.width = target.offsetWidth
    }
  }
}
</script>
<template>
  <VContainer
    class="v-container--vdp"
    fill-height
    flex-column
    align-stretch
    fluid
    pa-0
  >
    <p
      v-if="!id"
      class="empty"
    >
      No Appraisal Selected
    </p>
    <template v-else-if="ymmt">
      <NavbarVDP v-if="isMobileBreakpoint" />
      <HeaderVDP
        :id="id"
        :dense="width < 600"
      />
      <AppraisalCalculator
        :id="id"
        :value="value"
        :recon="0"
        :profit="0"
        :other="0"
        :dense="isMobileBreakpoint || isShrinked"
      />
      <div class="pa-4">
        <CupertinoSpringboard>
          <CupertinoGrid>
            <CarfaxWidget />
            <PhotosWidget :id="id" />
            <ACVEstimateWidget />
          </CupertinoGrid>
        </CupertinoSpringboard>
      </div>
    </template>
    <template v-else>
      <VSkeletonLoader
        v-if="pending"
        class="vpd--skeleton mx-auto"
        type="article, image@3"
        width="100%"
      />
      <p
        v-else
        class="empty appraisal-not-found"
      >
        Appraisal Not Found
      </p>
    </template>
  </VContainer>
</template>
<style lang="scss">
.v-container--vdp {
  background: #F2F2F6;

  .vdp--widget-open {
    display: flex !important;
  }

  .vpd--skeleton {
    .v-skeleton-loader__image {
      border-radius: 20px;
      display: inline-block;
      height: 140px;
      margin: 16px;
      width: 140px;
    }
  }

  p.empty {
    align-self: center;
    color: rgb(155 155 155);
    font-size: 24px;
    font-weight: 500;
    margin: auto;
  }
}

.theme--dark .v-container--vdp {
  background: #121212;
}
</style>
