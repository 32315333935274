<script>
export default {
  inject: ['authData', 'dataSource'],
  computed: {
    dealerNodes: ({ authData }) => authData.user?.dealers?.nodes || [],
    currentDealer: ({ authData }) => authData.user?.dealer?.node
  },
  methods: {
    async createAppraisal (vin) {
      if (!vin) return

      return await this.dataSource.createAppraisal(vin)
    }
  }
}
</script>
