<script>
export default {
  name: 'DialogWrapper',
  props: {
    pending: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonLabel: ({ pending }) => pending ? 'Pending request' : 'Submit'
  },
  async mounted () {
    // using visualViewport to trigger scroll when mobile keyboard is open
    window.visualViewport.addEventListener('resize', this.scrollToBottom)

    // @TODO: This can be removed once ProgrammaticDialog allows injecting custom classes
    await this.$nextTick()
    document.querySelector('.v-dialog__content')?.classList.add('appraisal-dialog--custom-wrapper')
  },
  beforeDestroy () {
    window.visualViewport.removeEventListener('resize', this.scrollToBottom)
  },
  methods: {
    // Prevent multiple clicks when submiting
    buttonAction () {
      if (this.pending) return
      this.$emit('submit')
    },
    scrollToBottom () {
      window.scroll({ top: document.documentElement.clientHeight - window.visualViewport.height })
    }
  }
}
</script>
<template>
  <VCard
    class="dialog-wrapper rounded-0"
  >
    <VCardActions
      class="pb-0 pt-1 px-3"
    >
      <VBtn
        color="primary"
        text
        min-width="0"
        class="px-2 subtitle-2 dialog-wrapper__cancel"
        @click="$emit('close')"
      >
        Cancel
      </VBtn>
    </VCardActions>
    <VCardTitle
      class="pt-0 px-5 mb-1 mt-n2 justify-space-between"
    >
      <h2 class="dialog-wrapper__title">
        {{ title }}
      </h2>
      <VBtn
        :aria-label="buttonLabel"
        width="48"
        height="48"
        color="primary"
        fab
        elevation="0"
        class="dialog-wrapper__action"
        :loading="pending"
        @click="buttonAction"
      >
        <VIcon
          medium
          color="white"
          v-text="'$arrowForward'"
        />
      </VBtn>
    </VCardTitle>
    <VCardText class="px-5">
      <slot />
    </VCardText>
  </VCard>
</template>
<style lang="scss">
.dialog-wrapper {
  &__title {
    font-size: 31px;
    font-weight: 700;
    letter-spacing: 0;
  }
}

.appraisal-dialog--custom-wrapper {
  .v-dialog--active {
    border-radius: 10px;
    max-width: 438px;
    width: 100% !important;
  }

  @include breakpoint("xs-only") {
    .dialog-transition {
      &-enter {
        transform: translateY(100%);
      }
    }

    .v-dialog--active {
      align-self: flex-end;
      border-radius: 10px 10px 0 0 !important;
      margin: 0;
      max-width: 100%;
    }
  }
}
</style>
