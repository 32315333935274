<script>
import { InputNumeric } from '@maxsystems/ui/elements'
import HeaderVDPBase from '@maxsystems/xplat/core/routes/VDP/components/Header/HeaderVDP.base.vue'

import ToolbarVDP from './ToolbarVDP/ToolbarVDP.vue'

export default {
  components: {
    ToolbarVDP,
    InputNumeric
  },
  mixins: [HeaderVDPBase]
}
</script>

<template>
  <header :class="['px-4 pt-5 pb-6 vdp__header', { 'vdp__header--dense': dense }]">
    <div class="d-flex justify-space-between">
      <div class="d-inline-flex align-center">
        <h1 class="d-inline-block mr-3 align-self-center">
          {{ title }}
        </h1>
      </div>
      <ToolbarVDP />
    </div>
    <div class="vdp__header__wrapper">
      <div class="vdp__header__item">
        <VSelect
          v-model="trimSelection"
          class="vdp__header__trim-select"
          dense
          :items="[{ name: trim || '-', value: trim }]"
          :menu-props="{
            contentClass: 'vdp__header__trim-select__menu',
          }"
          append-icon="$unfoldMoreHorizontal"
          flat
          hide-details
          spellcheck="false"
          solo
          item-text="name"
          return-object
        />
      </div>
      <div class="vdp__header__item vdp__header__item--vin d-flex align-center">
        <span
          v-if="vin"
          class="vdp__header__vin"
        >{{ vin }}</span>
      </div>
      <div class="vdp__header__item">
        <InputNumeric
          v-model="mileageFormatted"
          class="vdp__header__mileage d-inline-block"
          outlined
          disabled
          hide-details
          suffix="mi"
        />
      </div>
      <div class="vdp__header__item d-flex justify-end">
        <VSelect
          v-if="type"
          v-model="appraisalType"
          class="vdp__header__appraisal-type"
          :items="[{ name: type.toLowerCase(), value: type }]"
          :menu-props="{
            contentClass: 'vdp__header__appraisal-type__menu',
          }"
          color="primary"
          item-text="name"
          hide-details
          outlined
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss">
.vdp__header {
  &__appraisal-type__menu,
  &__trim-select__menu {
    margin-top: -10px;

    .v-list {
      padding: 0;
    }

    .v-list-item__title {
      text-transform: capitalize;
    }
  }

  &__trim-select__menu {
    min-width: fit-content !important;

    .v-list-item__title {
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 19px !important;
    }
  }
}

header.vdp__header {
  background-color: #FFF;
  position: relative;

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .vdp__header {
    &__wrapper {
      display: grid;
      grid-template-columns: min-content 100px auto;
    }

    &__item {
      &--vin {
        justify-content: flex-start;
        padding-right: 20px;
        position: relative;

        &::before {
          background-color: #545454;
          content: "";
          height: 16px;
          position: absolute;
          right: 10px;
          width: 1px;
        }
      }

      &:first-child {
        grid-column: 1 / -1;
      }
    }

    &__trim-select {
      width: fit-content;

      .v-input__slot,
      .v-input__control {
        min-height: auto !important;
      }

      .v-input__slot {
        background-color: unset !important;
        padding: 0 !important;
      }

      .v-icon {
        margin-top: 2px;
      }

      .v-list {
        padding: 0;
      }

      .v-select__selection {
        max-width: 100%;
      }

      .v-select__selections {
        min-width: unset;

        input {
          width: 0;
        }
      }

      .v-input__append-inner,
      .v-select__selection--comma {
        transition: opacity 0s;
      }

      .v-select__selection--comma {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        text-overflow: unset;
      }

      .v-input__append-inner {
        margin-left: -2px !important;
        padding-left: 0;

        .v-icon__svg {
          height: 18px !important;
          width: 14px !important;
        }
      }

      &.v-select--is-menu-active {
        margin-left: 0 !important;

        .v-input__append-inner {
          opacity: 0;
        }
      }
    }

    &__mileage {
      min-width: 100px;

      .v-text-field__slot {
        font-size: 14px;
      }

      .v-text-field__suffix {
        padding-left: 0;
      }

      .v-input__control {
        height: 29px;
      }

      .v-input__slot {
        min-height: 20px;
        padding: 0 8px !important;
      }

      &:not(.v-input--is-focused) {
        fieldset {
          color: #E5E5EA !important;
        }
      }
    }

    &__vin {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 17px;
      opacity: 0.7;
    }

    &__appraisal-type {
      max-width: fit-content;

      .v-input__control fieldset,
      .v-select__selection--comma,
      .v-icon {
        color: #007AFF !important;
      }

      .v-input__control {
        height: 28px;

        .v-input__append-inner {
          margin-top: 2px;
          padding-left: 0;
        }

        .v-select__selection--comma {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          max-width: 100%;
          text-transform: capitalize;
        }

        fieldset {
          border-radius: 100px;
        }
      }

      .v-input__slot {
        min-height: 28px;
        padding-left: 22px !important;
        padding-right: 16px !important;
      }

      .v-select__slot {
        input {
          width: 0;
        }
      }
    }
  }

  // Dense (medium and small viewport)
  &.vdp__header--dense {
    padding: 12px !important;

    h1 {
      font-size: 20px;
      line-height: 23px;
    }

    .vdp__header {
      &__wrapper {
        grid-template-columns: min-content 100px auto;
      }

      &__item {
        &--vin {
          padding-right: 16px;

          &::before {
            right: 8px;
          }
        }
      }

      &__vin {
        font-size: 12px;
        line-height: 14px;
      }

      &__trim-select {
        min-width: unset;
      }
    }
  }
}

// Extra small viewport
@media (max-width: 420px) {
  header.vdp__header {
    &.vdp__header--dense {
      h1 {
        font-size: 17px;
        line-height: 22px;
      }
    }

    .vdp__header {
      &__wrapper {
        grid-template-columns: 100px auto !important;
      }

      &__item {
        &--vin {
          justify-content: flex-end;
          padding-right: 0 !important;

          &::before {
            display: none;
          }
        }

        &:first-child {
          grid-column: auto;
        }
      }

      &__mileage {
        max-width: 112px;
      }

      &__trim-select {
        .v-input__append-inner {
          margin-right: -6px;
        }

        .v-select__selection--comma {
          max-width: 82px;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.theme--dark header.vdp__header {
  background-color: #272727;

  .vdp__header__mileage {
    &:not(.v-input--is-focused) {
      .v-input__slot {
        fieldset {
          color: rgba(255 255 255 / 24%) !important;
        }
      }
    }
  }
}
</style>
