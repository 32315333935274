<script>
import { withQuery } from '@maxsystems/client'
import { addIcons } from '@maxsystems/ui/vue'

import GetAppraisalPhotos from './GetAppraisalPhotos.gql'
import { CupertinoWidget } from '~/routes/VDP/components/Cupertino'

addIcons({
  camera: 'M4.626 20h14.748C21.108 20 22 19.12 22 17.357V8.71C22 6.914 21.108 6 19.374 6h-2.147c-.644 0-.842-.086-1.222-.526l-.677-.763C14.915 4.237 14.486 4 13.643 4h-3.336c-.859 0-1.288.237-1.7.711l-.678.763c-.38.423-.578.526-1.222.526H4.626C2.876 6 2 6.914 2 8.71v8.647C2 19.12 2.875 20 4.626 20Zm7.399-1.821c-2.758 0-4.971-2.253-4.971-5.1 0-2.811 2.213-5.064 4.97-5.064 2.726 0 4.922 2.253 4.922 5.065 0 2.897-2.196 5.099-4.921 5.099Zm6.573-7.522c-.611 0-1.107-.508-1.107-1.151 0-.644.496-1.152 1.107-1.152.61 0 1.123.508 1.123 1.152 0 .643-.512 1.151-1.123 1.151Zm-6.59 6.048c1.933 0 3.518-1.592 3.518-3.625 0-2-1.585-3.608-3.518-3.608-1.948 0-3.534 1.609-3.534 3.608 0 2.033 1.585 3.625 3.534 3.625Z',
  border: 'M5.92033 20.3822c0 1.9131-1.02962 3.0169-2.97855 3.0169C1.06639 23.3991 0 22.2953 0 20.3822v-9.0138C0 3.78947 3.86108 0 11.5465 0h8.9724c1.9489 0 3.0153 1.03015 3.0153 2.94328 0 1.91313-1.0664 2.98007-3.0153 2.98007H11.62c-3.6772 0-5.69967 1.94993-5.69967 5.77615v8.6827Z'
})

export default {
  name: 'PhotosWidget',
  components: {
    CupertinoWidget
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      query: withQuery(GetAppraisalPhotos),
      isLoading: true,
      data: null
    }
  },
  computed: {
    count: ({ data }) => data?.totalCount ?? 0,
    primaryPhoto: ({ data }) => data?.primary?.url ?? '',
    isEmpty: ({ count }) => count === 0,
    countText: ({ count }) => `${count} photo` + (count > 1 ? 's' : ''),
    buttonLabel: ({ isLoading, isEmpty }) => {
      if (isLoading) return 'Loading'
      if (isEmpty) return 'Upload photos'

      return 'Edit photos'
    }
  },
  watch: {
    id: {
      handler (id) {
        this.data = null
        this.isLoading = true
        this.getPhotos(id)
      },
      immediate: true
    }
  },
  destroyed () {
    this.query.abort()
  },
  methods: {
    async getPhotos (id) {
      this.query.abort()
      const { data } = await this.query.fetch({ id })

      this.isLoading = false
      this.data = data?.appraisal?.photos
    }
  }
}
</script>
<template>
  <CupertinoWidget class="photos-widget">
    <button
      :class="['photos-widget__button', { 'loading': isLoading }]"
      :aria-label="buttonLabel"
      :aria-disabled="isLoading"
    >
      <div
        v-if="isLoading | isEmpty"
        class="photos-widget__icon mx-auto"
      >
        <VSkeletonLoader
          v-if="isLoading"
          class="camera-loader"
          width="24"
          height="24"
          type="card"
        />
        <template v-if="isEmpty && !isLoading">
          <VIcon
            class="border border-top border-top--left"
            v-text="'$border'"
          />
          <VIcon
            class="border border-top border-top--right"
            v-text="'$border'"
          />
          <VIcon
            class="border border-bottom border-bottom--right"
            v-text="'$border'"
          />
          <VIcon
            class="border border-bottom border-bottom--left"
            v-text="'$border'"
          />
          <VIcon
            class="camera"
            v-text="'$camera'"
          />
        </template>
      </div>

      <template v-else>
        <VImg
          class="fill-height"
          :src="primaryPhoto"
          alt="Appraisal cover photo"
          gradient="180deg, rgb(196 196 196 / 0%) 0%, rgb(0 0 0 / 70%) 100%"
        />
        <span class="photos-widget__title font-weight-bold white--text">
          {{ countText }}
        </span>
      </template>
    </button>
  </CupertinoWidget>
</template>
<style lang="scss">
.photos-widget {
  background-color: #FFF;
  position: relative;

  .v-icon__component {
    height: 100%;
    width: 100%;
  }

  .camera-loader {
    /* stylelint-disable-next-line max-line-length */
    clip-path: path("M4.626 20h14.748C21.108 20 22 19.12 22 17.357V8.71C22 6.914 21.108 6 19.374 6h-2.147c-.644 0-.842-.086-1.222-.526l-.677-.763C14.915 4.237 14.486 4 13.643 4h-3.336c-.859 0-1.288.237-1.7.711l-.678.763c-.38.423-.578.526-1.222.526H4.626C2.876 6 2 6.914 2 8.71v8.647C2 19.12 2.875 20 4.626 20Zm7.399-1.821c-2.758 0-4.971-2.253-4.971-5.1 0-2.811 2.213-5.064 4.97-5.064 2.726 0 4.922 2.253 4.922 5.065 0 2.897-2.196 5.099-4.921 5.099Zm6.573-7.522c-.611 0-1.107-.508-1.107-1.151 0-.644.496-1.152 1.107-1.152.61 0 1.123.508 1.123 1.152 0 .643-.512 1.151-1.123 1.151Zm-6.59 6.048c1.933 0 3.518-1.592 3.518-3.625 0-2-1.585-3.608-3.518-3.608-1.948 0-3.534 1.609-3.534 3.608 0 2.033 1.585 3.625 3.534 3.625Z");
  }

  &__title {
    bottom: 1rem;
    font-size: 20px;
    left: 1rem;
    line-height: 2rem;
    position: absolute;
  }

  &__icon {
    height: 104px;
    position: relative;
    width: 104px;

    .border,
    .camera {
      position: absolute;
    }

    .camera svg,
    .border svg {
      fill: var(--v-primary-base) !important;
    }

    .border svg {
      height: 100%;
      width: 24px;
    }

    .border-top {
      top: 0;

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
        transform: rotate(90deg);
      }
    }

    .border-bottom {
      bottom: 0;

      &--left {
        left: 0;
        transform: rotate(-90deg);
      }

      &--right {
        right: 0;
        transform: rotate(180deg);
      }
    }

    .camera,
    .camera-loader {
      left: 50%;
      top: 50%;
    }

    .camera-loader {
      transform: translate(-50%, -50%) scale(2.4);
    }

    .camera {
      transform: translate(-50%, -50%);

      svg {
        height: 100%;
        width: 56px;
      }
    }
  }

  &__button {
    height: 100%;
    width: 100%;

    &.loading {
      cursor: none;
      pointer-events: none;
    }
  }
}

.theme--dark {
  .photos-widget {
    background-color: #272727;
  }
}
</style>
