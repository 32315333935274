<script>
export default {
  inject: ['theme'],
  props: {
    size: {
      type: [Number, String],
      default: 64
    }
  },
  data: () => ({
    paths: [
      'M320.116 20.495c-5.035-2.907-6.76-9.344-3.853-14.38 2.907-5.033 9.345-6.759 14.379-3.852 5.035 2.907 6.76 9.345 3.853 14.38-2.906 5.034-9.344 6.759-14.379 3.852Zm-1.574-13.063a7.895 7.895 0 1 0 13.675 7.894 7.895 7.895 0 0 0-13.675-7.894Zm-76.426 13.846c-5.035-2.907-6.76-9.345-3.853-14.38 2.907-5.034 9.345-6.759 14.379-3.852 5.035 2.906 6.76 9.344 3.853 14.379-2.907 5.034-9.344 6.76-14.379 3.853Zm-1.574-13.064a7.895 7.895 0 1 0 13.675 7.895 7.895 7.895 0 0 0-13.675-7.895Z',
      'm309.99 13.215-10.37.066-.017-2.632 10.371-.066.016 2.632Zm-18.148.115-10.37.067-.017-2.631 10.371-.067.016 2.632Zm-18.148.117-10.37.066-.017-2.631 10.371-.067.016 2.632Zm-16.779 10.277 7.379 7.285 1.849-1.872-7.38-7.286-1.848 1.873Zm20.294 20.036-7.38-7.286 1.849-1.873 7.38 7.286-1.849 1.873Zm7.367 3.609-.021-.02-1.849 1.872 7.38 7.286.017-.017.021.02 1.849-1.872-7.38-7.286-.017.017Zm11.044 14.602 7.38 7.286 1.849-1.872-7.38-7.286-1.849 1.872Zm12.915 12.751 7.38 7.286 1.849-1.873-7.38-7.286-1.849 1.873Zm-34.651-7.282 7.286-7.38-1.873-1.85-7.286 7.38 1.873 1.85Zm-5.465 5.534-7.286 7.38-1.872-1.849 7.286-7.38 1.872 1.85Zm31.201 18.552 10.37-.066-.016-2.632-10.371.066.017 2.632Zm-18.148.116 10.37-.066-.016-2.632-10.371.067.017 2.631Zm-18.148.116 10.37-.066-.016-2.632-10.371.067.017 2.631Zm60.153-64.24.067 10.37 2.631-.016-.066-10.37-2.632.016Zm2.724 18.132v-.03l-2.632.017.066 10.37h.025v.03l2.631-.017-.066-10.37h-.024Zm-2.516 18.135.066 10.37 2.632-.017-.066-10.37-2.632.017Z',
      'M238.263 85.116c-2.907 5.035-1.182 11.472 3.853 14.38 5.035 2.906 11.472 1.181 14.379-3.854 2.907-5.034 1.182-11.472-3.853-14.379-5.034-2.907-11.472-1.182-14.379 3.853Zm5.169 12.1a7.895 7.895 0 1 1 7.895-13.674 7.895 7.895 0 0 1-7.895 13.674Zm72.831-12.1c-2.907 5.035-1.182 11.472 3.853 14.38 5.035 2.906 11.473 1.181 14.379-3.854 2.907-5.034 1.182-11.472-3.853-14.379-5.034-2.907-11.472-1.182-14.379 3.853Zm5.169 12.1a7.895 7.895 0 1 1 7.895-13.674 7.895 7.895 0 0 1-7.895 13.674Z',
      'm174.799 22.156 5.113 9.022-2.29 1.298-5.112-9.023 2.289-1.297Zm-20.184 9.022 5.113-9.022 2.289 1.297-5.113 9.023-2.289-1.298Zm29.132 6.767 5.112 9.023-2.289 1.297-5.113-9.022 2.29-1.298Zm-38.08 9.023 5.113-9.023 2.29 1.298-5.113 9.022-2.29-1.297Zm-8.947 15.789 5.113-9.022 2.289 1.297-5.112 9.023-2.29-1.298Zm55.974-9.022 5.113 9.022-2.29 1.298-5.113-9.023 2.29-1.297Zm-64.921 24.812 5.112-9.023 2.29 1.298-5.113 9.022-2.289-1.297Zm73.868-9.023 5.113 9.023-2.29 1.297-5.112-9.022 2.289-1.298ZM145.534 91.79h-10.226v-2.632h10.226v2.632Zm17.895 0h-10.226v-2.632h10.226v2.632Zm17.894 0h-10.225v-2.632h10.225v2.632Zm17.895 0h-10.225v-2.632h10.225v2.632Z',
      'M167.264 1c-5.814 0-10.527 4.713-10.527 10.526 0 5.814 4.713 10.527 10.527 10.527 5.813 0 10.526-4.713 10.526-10.527C177.79 5.713 173.077 1 167.264 1Zm-7.895 10.526a7.894 7.894 0 1 1 15.789 0 7.894 7.894 0 1 1-15.789 0Zm55.263 68.421c-5.814 0-10.527 4.713-10.527 10.527 0 5.813 4.713 10.526 10.527 10.526 5.813 0 10.526-4.713 10.526-10.526 0-5.814-4.713-10.527-10.526-10.527Zm-7.895 10.527a7.895 7.895 0 1 1 15.79 0 7.895 7.895 0 0 1-15.79 0Zm-94.737 0c0-5.814 4.713-10.527 10.526-10.527 5.814 0 10.527 4.713 10.527 10.527 0 5.813-4.713 10.526-10.527 10.526C116.713 101 112 96.287 112 90.474Zm10.526-7.895a7.894 7.894 0 0 0-7.894 7.895 7.894 7.894 0 1 0 15.789 0 7.895 7.895 0 0 0-7.895-7.895Z',
      'm70.749 29.294 7.286-7.38 1.873 1.85-7.287 7.38-1.872-1.85Zm-5.465 5.536-7.286 7.38 1.873 1.848 7.286-7.38-1.873-1.849ZM54.41 49.555l-.02.02.017.018-7.286 7.38-1.873-1.85.02-.02-.017-.017 7.286-7.38 1.873 1.849ZM32.5 68l7.287-7.38 1.873 1.85-7.286 7.38L32.5 68ZM19.75 80.915l7.286-7.38 1.873 1.849-7.286 7.38-1.873-1.849Zm9.034-47.495-7.38-7.287 1.85-1.872 7.379 7.286-1.849 1.872Zm5.535 5.464 7.38 7.286 1.849-1.873-7.38-7.286-1.849 1.873Zm53.992-.19-.066-10.37 2.632-.017.066 10.37-2.632.017Zm.117 18.148-.067-10.37 2.632-.017.066 10.37-2.631.017Zm.116 18.148-.067-10.37 2.632-.017.066 10.37-2.631.017ZM10 28.324l.066 10.37 2.632-.016-.066-10.37-2.632.016Zm.116 18.148.066 10.37 2.632-.016-.066-10.37-2.632.016Zm.116 18.148.067 10.37 2.631-.016-.066-10.37-2.632.016ZM74.24 88.477l-10.37.067.017 2.631 10.37-.066-.017-2.632ZM56.109 91.2h.03l-.018-2.631-10.37.066v.025h-.03l.018 2.631 10.37-.066v-.024Zm-18.135-2.515-10.37.066.016 2.632 10.37-.066-.016-2.632Z',
      'M80.48 7.116c2.907-5.035 9.345-6.76 14.38-3.853 5.034 2.907 6.759 9.345 3.853 14.38-2.907 5.034-9.345 6.759-14.38 3.852-5.034-2.907-6.76-9.344-3.852-14.379Zm13.064-1.574a7.895 7.895 0 1 0-7.895 13.674 7.895 7.895 0 0 0 7.895-13.674ZM16.642 3.263C11.608.356 5.17 2.081 2.263 7.116s-1.182 11.473 3.853 14.38c5.035 2.906 11.473 1.181 14.38-3.854 2.906-5.034 1.181-11.472-3.854-14.379Zm-12.1 5.169a7.895 7.895 0 1 1 13.674 7.895A7.895 7.895 0 0 1 4.542 8.432Zm76.721 76.684c2.907-5.035 9.345-6.76 14.38-3.853 5.034 2.907 6.759 9.345 3.852 14.38-2.907 5.034-9.345 6.759-14.379 3.852-5.035-2.907-6.76-9.344-3.853-14.379Zm13.064-1.574a7.895 7.895 0 1 0-7.895 13.674 7.895 7.895 0 0 0 7.895-13.674Zm-77.685-2.279c-5.034-2.907-11.472-1.182-14.379 3.853s-1.182 11.472 3.853 14.38c5.035 2.906 11.473 1.181 14.38-3.854 2.906-5.034 1.181-11.472-3.854-14.379Zm-12.1 5.169a7.895 7.895 0 1 1 13.674 7.895 7.895 7.895 0 0 1-13.674-7.895Z'
    ]
  })
}
</script>

<template>
  <svg
    viewBox="0 0 336 101"
    :height="size"
  >
    <path
      v-for="(d, key) in paths"
      :key="key"
      :fill="$vuetify.theme.themes.light[theme.isDark ? 'light' : 'dark'].base"
      :d="d"
    />
  </svg>
</template>
