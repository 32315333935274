<script>
export default {
  name: 'CarfaxWidgetDetail',
  inject: ['authData', 'appraisalData'],
  data: () => ({
    mustLogin: false,
    loading: true
  }),

  computed: {
    report: ({ appraisalData }) => appraisalData.node?.vehicle?.carfax || {},
    hasReport: ({ report }) => !!report.fourPillars,

    /** @TODO replace temporary url with real one once this merges */
    url: ({ appraisalData: { id }, authData }) =>
      `https://appraisals-git-feat-carfax-proxy-maxdigital.vercel.app/embed/carfax/${id}?user=${authData.user.id}`,

    // override in mobile app
    redirectUri: () => window.location,
    loginURL: ({ authData, redirectUri }) =>
      `https://api.firstlook.biz/v1/carfaxconnect/oauth/initiate/${authData.user.id}` +
      `?redirect_uri=${encodeURIComponent(redirectUri)}`
  },

  watch: {
    'appraisalData.id': {
      handler () {
        if (this.hasReport) return
        this.getReport()
      },
      immediate: true
    }
  },

  methods: {
    async getReport () {
      this.mustLogin = false
      this.loading = true
      const { errors } = await this.appraisalData.query(
        'vehicle { carfax { fourPillars { icon text } url } }'
      )
      this.mustLogin = Boolean(
        errors?.some(({ extensions }) => extensions.code === 'FORBIDDEN')
      )
      if (!this.hasReport || this.mustLogin) this.loading = false
    }
  }
}
</script>
