var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['acv-detail fill-height', {'acv-detail--dense': _vm.dense}]},[_c('ACVEstimateHeader',{attrs:{"value":38500,"dense":_vm.dense}}),_c('div',{staticClass:"acv-detail__form fill-height black--text"},[_c('div',{staticClass:"acv-detail__title font-weight-medium pb-1"},[_vm._v(" Verify Condition Information ")]),_c('div',{staticClass:"acv-detail__subtitle pb-5"},[_vm._v(" Please confirm the information below ")]),_c('div',{staticClass:"acv-detail__board"},[_vm._m(0),_c('div',{staticClass:"acv-detail__board-item mb-2"},[_c('div',{staticClass:"acv-detail__board-item-title font-weight-medium"},[_vm._v(" Title Branded ")]),_c('div',{staticClass:"acv-detail__board-item-sections"},[_c('div',{staticClass:"acv-detail__board-item-section acv-detail__board-item-subtitle pb-1"},[_vm._v(" Vehicle involved in an incident that required insurance involvments ")]),(_vm.withCondition)?_c('div',{staticClass:"acv-detail__board-item-section acv-detail__board-customer-condition"},[_vm._m(1),_c('VDivider')],1):_vm._e(),_c('div',{class:['acv-detail__board-item-section acv-detail__customer-action center', {'no-condition': !_vm.withCondition} ]},[_c('div',{staticClass:"acv-detail__customer-action-container"},[_c('FieldBoolean',{attrs:{"color":_vm.color,"value":{ boolean: _vm.titleBranded },"dense":_vm.dense,"schema":{
                  options: [ { description: 'Yes' }, { description: 'No' }]
                }},on:{"input":function (ref) {
                                var boolean = ref.boolean;

                                return _vm.titleBranded = boolean;
}}})],1)]),_c('div',{staticClass:"acv-detail__board-item-section acv-detail__customer-answer text-capitalize"},[_vm._v(" yes ")])])]),_c('div',{staticClass:"acv-detail__board-item mb-2"},[_c('div',{staticClass:"acv-detail__board-item-title font-weight-medium"},[_vm._v(" Body Damage ")]),_c('div',{staticClass:"acv-detail__board-item-sections"},[_c('div',{staticClass:"acv-detail__board-item-section acv-detail__board-item-subtitle pb-1"},[_vm._v(" Description ")]),(_vm.withCondition)?_c('div',{staticClass:"acv-detail__board-item-section acv-detail__board-customer-condition"},[_vm._m(2),_c('VDivider')],1):_vm._e(),_c('div',{class:['acv-detail__board-item-section acv-detail__customer-action center', {'no-condition': !_vm.withCondition} ]},[_c('div',{staticClass:"acv-detail__customer-action-container"},[_c('FieldBoolean',{attrs:{"color":_vm.color,"value":{ boolean: _vm.bodyDamage },"dense":_vm.dense,"schema":{
                  options: [{ description: 'Yes' }, { description: 'No' }]
                }},on:{"input":function (ref) {
                                var boolean = ref.boolean;

                                return _vm.bodyDamage = boolean;
}}})],1),_c('div',{staticClass:"acv-detail__customer-action-option"},[_c('div',{staticClass:"acv-detail__option-title my-2"},[_vm._v(" Level of damage ")]),_c('FieldRadio',{attrs:{"color":_vm.color,"value":{ text: 'Moderate' },"schema":{
                  options: [
                    { description: 'Minor', order: 1 },
                    { description: 'Major', order: 2 },
                    { description: 'Moderate', order: 3 } ]
                }}})],1)]),_vm._m(3)])])])]),_c('div',{staticClass:"acv-detail__footer"},[_c('VBtn',{staticClass:"acv-detail__confirmation white--text",attrs:{"color":_vm.theme.isDark ? '#004066' : '#004E7D',"elevation":"0"}},[_vm._v(" Update ")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"acv-detail__board-header pb-4"},[_c('div',{staticClass:"acv-detail__board-header-title"}),_c('div',{staticClass:"acv-detail__board-header-title center font-weight-medium"},[_vm._v(" Dealer condition confirmation ")]),_c('div',{staticClass:"acv-detail__board-header-title font-weight-medium"},[_vm._v(" Customer answer ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-baseline"},[_c('div',[_c('div',{staticClass:"acv-detail__condition-answer"},[_vm._v(" Customer answer ")])]),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"acv-detail__condition-value customer-answer"},[_vm._v(" Yes ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-baseline"},[_c('div',[_c('div',{staticClass:"acv-detail__condition-answer"},[_vm._v(" Customer answer ")]),_c('div',{staticClass:"acv-detail__condition-level"},[_vm._v(" Level of Damage ")])]),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"acv-detail__condition-value customer-answer"},[_vm._v(" Yes ")]),_c('div',{staticClass:"acv-detail__condition-value"},[_vm._v(" Minor ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"acv-detail__board-item-section acv-detail__customer-answer"},[_c('div',{staticClass:"mb-5"},[_vm._v(" Yes ")]),_c('div',{staticClass:"acv-detail__option-title"},[_vm._v(" Level of damage ")]),_c('div',{staticClass:"acv-detail__option-answer"},[_vm._v(" Minor ")])])}]

export { render, staticRenderFns }