import WindowStickerWidgetDetail from './WindowStickerWidgetDetail.vue'

// this is a start in direction of a plugin system
// similar to lilypad: https://github.com/maxsystems/lilypad/blob/main/src/patterns/filterable/README.md
export default {
  id: 'window-sticker',
  theme: {
    dark: { primary: '#a0a0a0' },
    light: { primary: '#a0a0a0' }
  },
  logo: null,
  topBar: { hasCancel: false },
  widget: null,
  detail: WindowStickerWidgetDetail
}
