import appraisalFields from './appraisalFields.fragment.gql'
import CreateAppraisal from './createAppraisal.gql'
import GetAppraisal from './getAppraisal.gql'
import GetAppraisals from './getAppraisals.gql'
import GetDisclosures from './getDisclosures.gql'
import UpdateAppraisals from './updateAppraisals.gql'
import valuationFragment from './valuation.fragment.gql'

export const createAppraisal = CreateAppraisal + appraisalFields
export const getAppraisal = GetAppraisal
export const getAppraisals = GetAppraisals + appraisalFields
export const getDisclosures = GetDisclosures + valuationFragment
export const updateAppraisals = UpdateAppraisals
