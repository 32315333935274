<script>
import Vue from 'vue'

import { currency } from '@maxsystems/ui/vue/filters'

export default Vue.extend({
  name: 'AppraisalCalculator',
  props: {
    value: {
      type: Number,
      default: 0
    },
    recon: {
      type: Number,
      default: 0
    },
    profit: {
      type: Number,
      default: 0
    },
    other: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formatterOptions: {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      },
      // TODO: Need to get current value and targets from backend.
      internalAppraisalPrice: this.value,
      internalSalesPrice: this.value + this.recon + this.profit + this.other,
      internalTargets: {
        recon: this.recon,
        profit: this.profit,
        other: this.other
      },
      // there might be some follow up work to make this configurable. This field could be either appraisal or sales
      activeInput: 'appraisal'
    }
  },
  computed: {
    additionalValuesSum () {
      return (
        this.internalTargets.recon +
        this.internalTargets.profit +
        this.internalTargets.other
      )
    },
    additionalValues () {
      return [
        {
          label: 'Recon',
          customClasses: 'error--text',
          price: currency(this.internalTargets.recon)
        },
        {
          label: 'Profit',
          customClasses: 'success--text',
          price: currency(this.internalTargets.profit)
        },
        {
          label: 'Other',
          customClasses: null,
          price: currency(this.internalTargets.other)
        }
      ]
    },
    appraisalPrice: {
      get: ({ internalAppraisalPrice }) => internalAppraisalPrice,
      set (newValue) {
        this.internalAppraisalPrice = newValue
        this.internalSalesPrice = newValue + this.additionalValuesSum
      }
    },
    salesPrice: {
      get: ({ internalSalesPrice }) => internalSalesPrice,
      set (newValue) {
        this.internalSalesPrice = newValue
        this.internalAppraisalPrice = Math.max(
          0,
          newValue - this.additionalValuesSum
        )
      }
    }
  },
  watch: {
    id () {
      this.internalAppraisalPrice = this.value
      const defaultSalesPrice =
        this.value + this.recon + this.profit + this.other
      this.internalSalesPrice = defaultSalesPrice
    },
    internalAppraisalPrice (newPrice) {
      this.$emit('input', newPrice)
    }
  },
  methods: {
    updateTargets (targets) {
      this.internalTargets = targets

      if (this.activeInput === 'appraisal') {
        this.internalSalesPrice =
          this.internalAppraisalPrice + this.additionalValuesSum
      } else {
        this.internalAppraisalPrice = Math.max(
          0,
          this.internalSalesPrice - this.additionalValuesSum
        )
      }
    },
    setMinimumSalesPrice () {
      this.salesPrice = this.additionalValuesSum + this.internalAppraisalPrice
    }
  }
})
</script>
