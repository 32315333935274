<script>
import routable from 'vuetify/lib/mixins/routable'

export default {
  name: 'CupertinoWidget',
  mixins: [
    routable
  ],
  props: {
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    },
    type: {
      type: String,
      default: null,
      validator: value => ['info', 'success', 'warning', 'error'].includes(value)
    }
  },
  computed: {
    classes: vm => [
      'cupertino__widget',
      {
        'cupertino__widget--sm': !(vm.md || vm.lg),
        'cupertino__widget--md': vm.md && !vm.lg,
        'cupertino__widget--lg': vm.lg,
        [`cupertino__widget--${vm.type}`]: vm.type
      }
    ]
  },
  render: function (h) {
    const { tag, data } = this.generateRouteLink()
    return h(tag, data, [
      h('div', {
        class: 'cupertino__widget__content'
      }, this.$slots.default)
    ])
  }
}
</script>

<style lang="scss">
.cupertino__widget {
  $widget-dimension-min: 170px;
  $widget-dimension-max: 356px;

  background-color: #FFF;
  border-radius: 24px;
  color: rgb(0 0 0 / 70%);
  display: block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  max-width: fit-content;
  outline: transparent 1px solid;
  overflow: hidden;

  &:hover {
    outline-style: solid;
    outline-width: 1px;
  }

  &--info {
    background-color: #DDF4FF;
    color: #0969DA;
    outline-color: rgb(87 174 255 / 40%);
  }

  &--success {
    background-color: #DAFBE1;
    color: #1A7F37;
    outline-color: rgb(74 194 107 / 40%);
  }

  &--warning {
    background-color: #FBF5EC;
    color: #D57A2D;
    outline-color: rgb(213 122 45 / 40%);
  }

  &--error {
    background-color: #F2E4E3;
    color: #B41D1D;
    outline-color: rgb(180 29 29 / 40%);
  }

  &--sm {
    grid-column: span 1;
    grid-row: span 1;

    .cupertino__widget__content {
      height: $widget-dimension-min;
      width: $widget-dimension-min;
    }
  }

  &--md {
    grid-column: span 2;
    grid-row: span 1;

    .cupertino__widget__content {
      height: $widget-dimension-min;
      width: $widget-dimension-max;
    }
  }

  &--lg {
    grid-column: span 2;
    grid-row: span 2;

    .cupertino__widget__content {
      height: $widget-dimension-max;
      width: $widget-dimension-max;
    }
  }

  &.router-link-active {
    outline-color: var(--v-primary-base);
    outline-width: 3px;
  }
}

.theme--dark {
  .cupertino__widget {
    background: #272727;
    color: rgb(255 255 255 / 50%);

    &--info {
      background-color: rgb(56 139 233 / 15%);
      color: #58A6FF;
      outline-color: rgb(56 139 233 / 40%);
    }

    &--success {
      background-color: rgb(46 160 67 / 15%);
      color: #3FB950;
      outline-color: rgb(46 160 67 / 40%);
    }

    &--warning {
      background-color: rgb(187 128 9 / 15%);
      color: #D29922;
      outline-color: rgb(187 128 9 / 40%);
    }

    &--error {
      background: rgb(248 81 73 / 15%);
      color: #F85149;
      outline-color: rgb(248 81 73 / 40%);
    }
  }
}

</style>
