<script>
import ToolbarVDPBase from '@maxsystems/xplat/core/routes/VDP/components/Header/ToolbarVDP/ToolbarVDP.base.vue'

import ToolbarBtnShare from './ToolbarBtnShare.vue'
import WindowStickerBtn from './WindowStickerBtn.vue'

export default {
  components: {
    ToolbarBtnShare,
    WindowStickerBtn
  },
  mixins: [ToolbarVDPBase]
}
</script>

<template>
  <section class="toolbar-vdp d-flex flex-nowrap">
    <WindowStickerBtn />
    <ToolbarBtnShare />
  </section>
</template>
