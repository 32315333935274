<script>
import AppraisalItemBase from '@maxsystems/xplat/core/routes/SRP/components/AppraisalItem.base.vue'

export default {
  mixins: [AppraisalItemBase]
}
</script>

<template>
  <VCard
    tile
    elevation="0"
    tabindex="-1"
    class="v-card--appraisal px-2"
    :ripple="false"
    :to="{ name: 'vdp', params: { id: appraisal.id } }"
  >
    <VContainer
      fluid
      class="vehicle pt-2 pb-0 pr-3 rounded-lg"
    >
      <VRow no-gutters>
        <VCol cols="9">
          <div
            v-if="!hasValue"
            class="vehicle__dot"
          />
          <div
            :title="title"
            class="vehicle__title text-truncate"
          >
            {{ title }}
          </div>
        </VCol>
        <VCol
          cols="3"
          class="d-flex justify-end align-center"
        >
          <div
            :title="value"
            class="text-body-2 vehicle__value"
          >
            {{ value }}
          </div>
        </VCol>
      </VRow>
      <div
        :title="subtitle"
        class="vehicle__subtitle text-truncate text-body-2"
        v-text="subtitle"
      />
      <div
        class="vehicle__vin text-body-2"
        v-text="vin"
      />
      <VRow
        no-gutters
        class="vehicle__footer text-caption pb-2"
      >
        <VCol cols="8">
          {{ odometer }}
        </VCol>
        <VCol
          cols="4"
          class="text-right"
        >
          {{ updatedAt }}
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<style lang="scss">
%focused-appraisal {
  .vehicle {
    background-color: var(--v-primary-base) !important;

    &__dot {
      background-color: #FFF;
    }

    &__title,
    &__subtitle {
      color: #FFF;
    }

    &__value,
    &__vin,
    &__footer {
      color: rgb(255 255 255 / 70%);
    }
  }
}

.v-card--appraisal {
  background-color: transparent !important;
  padding-left: 2.375rem;

  &::before {
    content: none;
  }

  .vehicle {
    padding-left: 1.875rem;

    &__dot {
      background-color: var(--v-primary-base);
      border-radius: 5px;
      height: 10px;
      left: 1.25rem;
      position: absolute;
      top: 14px;
      width: 10px;
    }

    &__title {
      font-size: 17px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 2px;
    }

    &__subtitle,
    &__value {
      font-weight: 500 !important;
      line-height: 18px !important;
    }

    &__vin {
      padding-bottom: 3px;
    }

    &__vin,
    &__footer {
      font-weight: 400 !important;
      line-height: 18px !important;
    }
  }

  &.theme--light {
    .vehicle {
      &__title,
      &__subtitle {
        color: #000;
      }

      &__value,
      &__vin,
      &__footer {
        color: #76767B;
      }

      &__footer {
        border-bottom: 1px solid rgb(0 0 0 / 12%);
      }
    }
  }

  &.theme--dark {
    .vehicle {
      &__title,
      &__subtitle {
        color: #FFF;
      }

      &__value,
      &__vin,
      &__footer {
        color: rgb(255 255 255 / 70%);
      }

      &__footer {
        border-bottom: 1px solid rgb(255 255 255 / 12%);
      }
    }
  }

  &.router-link-exact-active {
    .vehicle {
      &__dot {
        background-color: var(--v-primary-base);
      }

      &__footer {
        border-bottom: none;
      }
    }

    &.theme--light .vehicle {
      background-color: #DCDCDC;
    }

    &.theme--dark .vehicle {
      background-color: #464646;
    }

    &:focus {
      @extend %focused-appraisal;
    }
  }
}

.v-virtual-scroll:focus-within {
  .v-card--appraisal {
    &.router-link-exact-active {
      @extend %focused-appraisal;
    }
  }
}
</style>
