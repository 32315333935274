import Vue from 'vue'

import { auth, authData } from '@maxsystems/client'
import { vuetify } from '@maxsystems/ui/vue'

import App from './App.vue'
import Theme from './plugins/theme'
import router from './routes'

Vue.use(auth)
Vue.use(Theme)

export default new Vue({
  router,
  vuetify,
  provide: {
    authData
  },
  data: () => ({
    mq: window.matchMedia('(prefers-color-scheme: dark)')
  }),
  created () {
    this.onPrefersColorSchemeChanged(this.mq)
    this.mq.onchange = this.onPrefersColorSchemeChanged
  },
  methods: {
    onPrefersColorSchemeChanged ({ matches: preferDark }) {
      vuetify.framework.theme.dark = preferDark
      document.documentElement.style.setProperty('color-scheme', preferDark ? 'dark' : 'light')
      document.head.querySelector('link[rel="mask-icon"]').setAttribute('color', preferDark ? '#FFF' : '#2B73E3')
    }
  },
  render: h => h(App)
}).$mount('#app')
