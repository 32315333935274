<script>
import { number, currency } from '@maxsystems/ui/vue/filters'

import getTimeAgo from '~/filters/timeago'

export default {
  name: 'AppraisalItem',
  props: {
    appraisal: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentDate: new Date()
  }),
  computed: {
    hasValue: ({ appraisal }) => Boolean(appraisal.value?.amount),
    odometer: ({ appraisal }) =>
      number(appraisal.vehicle?.odometer?.value || 0) + ' miles',
    subtitle: ({ appraisal }) => {
      if (!appraisal.vehicle) return
      const {
        ymmt: { trim },
        color
      } = appraisal.vehicle
      return (
        [trim, color?.name].filter(item => item?.trim()).join(' - ') || '-'
      )
    },
    title: ({ appraisal }) => {
      if (!appraisal.vehicle?.ymmt) return
      const { year, make, model } = appraisal.vehicle.ymmt
      return [year, make, model].join(' ')
    },
    updatedAt: ({ appraisal, currentDate }) =>
      getTimeAgo(appraisal.updatedAt, currentDate),
    value: ({ appraisal }) =>
      appraisal.value?.amount ? currency(appraisal.value.amount) : '$-',
    vin: ({ appraisal }) => appraisal.vehicle?.vin
  }
}
</script>
