import Vue from 'vue'

export const state = {
  sidebar: Vue.observable({
    isOpen: false
  }),

  widget: Vue.observable({
    isOpen: false,
    current: null
  })
}

const uiState = {
  /**
   * Controls the sidebar state
   */
  sidebar: {
    /**
     * Whether the sidebar is open or closed by the user
     */
    get isOpen () { return state.sidebar.isOpen },
    /**
     * The sidebar is considered soft-closed when it got closed as a side effect of the user opening a widget's detail view
     */
    get isSoftClosed () { return !state.sidebar.isOpen || uiState.widget.isOpen },
    toggle: () => {
      if (state.widget.isOpen) {
        Vue.set(state.sidebar, 'isOpen', true)

        uiState.widget.close()
        return
      }

      Vue.set(state.sidebar, 'isOpen', !state.sidebar.isOpen)
    },
    open: () => {
      Vue.set(state.sidebar, 'isOpen', true)

      uiState.widget.close()
    },
    close: () => Vue.set(state.sidebar, 'isOpen', false)
  },
  /**
   * Controls the widget detail view pane state
   */
  widget: {
    get isOpen () { return state.widget.isOpen },
    get current () { return state.widget.current },
    open: current => {
      Vue.set(state.widget, 'current', current)
      Vue.set(state.widget, 'isOpen', true)
    },
    close: () => {
      Vue.set(state.widget, 'current', null)
      Vue.set(state.widget, 'isOpen', false)
    }
  }
}

export default uiState
