import CarfaxLogo from './CarfaxLogo.vue'
import CarfaxWidget from './CarfaxWidget'
import CarfaxWidgetDetail from './CarfaxWidgetDetail.vue'

// this is a start in direction of a plugin system
// similar to lilypad: https://github.com/maxsystems/lilypad/blob/main/src/patterns/filterable/README.md
export default {
  id: 'carfax',
  theme: {
    dark: { primary: '#3777BC' },
    light: { primary: '#3777BC' }
  },
  logo: CarfaxLogo,
  topBar: { hasCancel: false },
  widget: CarfaxWidget,
  detail: CarfaxWidgetDetail
}
