<script>
import { ProgrammaticDialog, InputNumeric } from '@maxsystems/ui/elements'
import CalculatorTargetsDialogBase from '@maxsystems/xplat/core/routes/VDP/components/CalculatorTargetsDialog.base.vue'

import DialogWrapper from '~/components/DialogWrapper'

const Component = {
  mixins: [CalculatorTargetsDialogBase],
  components: {
    InputNumeric,
    DialogWrapper
  }
}

export const CalculatorTargetsDialog = ProgrammaticDialog(Component)

export default Component
</script>

<template>
  <DialogWrapper
    title="Edit Targets"
    class="calculator-dialog"
    v-on="$listeners"
    @submit="saveTargets"
    @close="$emit('close', null)"
  >
    <VRow dense>
      <VCol cols="4">
        <VSubheader
          id="recon"
          class="calculator-dialog__input-label"
        >
          Recon
        </VSubheader>
      </VCol>
      <VCol
        cols="7"
        class="px-2"
      >
        <InputNumeric
          ref="recon"
          v-model="targets.recon"
          :options="formatterOptions"
          aria-labelledby="recon"
          :autofocus="current === 'recon'"
          class="rounded-lg"
          maxlength="7"
          dense
          outlined
        />
      </VCol>
    </VRow>
    <VRow dense>
      <VCol cols="4">
        <VSubheader
          id="profit"
          class="calculator-dialog__input-label"
        >
          Profit
        </VSubheader>
      </VCol>
      <VCol
        cols="7"
        class="px-2"
      >
        <InputNumeric
          ref="profit"
          v-model="targets.profit"
          :options="formatterOptions"
          aria-labelledby="profit"
          :autofocus="current === 'profit'"
          class="rounded-lg"
          maxlength="7"
          dense
          outlined
        />
      </VCol>
    </VRow>
    <VRow dense>
      <VCol cols="4">
        <VSelect
          id="other"
          v-model="select"
          class="rounded-lg"
          :items="choices"
          dense
          outlined
        />
      </VCol>
      <VCol
        cols="7"
        class="px-2"
      >
        <InputNumeric
          ref="other"
          v-model="targets.other"
          :options="formatterOptions"
          aria-labelledby="other"
          :autofocus="current === 'other'"
          class="rounded-lg"
          maxlength="7"
          dense
          outlined
        />
      </VCol>
      <VCol
        cols="1"
        class="ml-n3"
      >
        <VBtn
          aria-label="+"
          height="48"
          width="48"
          disabled
          plain
          icon
        >
          <VIcon
            size="28"
            v-text="'$add'"
          />
        </VBtn>
      </VCol>
    </VRow>
  </DialogWrapper>
</template>

<style lang="scss">
.calculator-dialog {
  overflow: hidden;

  a {
    color: var(--v-primary-base) !important;
  }

  .v-card__text {
    .row {
      height: 48px;
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }

  .v-input__slot {
    font-size: 18px;
    height: 48px;
    padding-left: 16px !important;
    padding-right: 16px !important;

    .v-input__append-inner {
      margin-top: 12px !important;
    }
  }

  .v-text-field--outlined {
    fieldset {
      background-color: var(--v-grey-lighten4);
      border-color: var(--v-grey-lighten4);
    }

    &.v-select fieldset {
      background-color: transparent;
    }

    &.v-input--is-focused fieldset,
    &.v-input--has-state fieldset {
      border-color: var(--v-primary-base);
    }
  }

  .calculator-dialog__input-label {
    color: black !important;
    font-size: 18px;
  }

  &.theme--dark {
    .calculator-dialog__input-label {
      color: white !important;
    }
  }
}
</style>
