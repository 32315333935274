<script>
import DealerSwitcherBase from '@maxsystems/xplat/core/routes/SRP/components/DealerSwitcher.base.vue'

export default {
  mixins: [DealerSwitcherBase]
}
</script>

<template>
  <VAutocomplete
    ref="dealer-switcher"
    v-model="value"
    class="dealer-switcher"
    dense
    append-icon="$unfoldMoreHorizontal"
    prepend-inner-icon="$search"
    flat
    :items="dealers"
    :loading="loading"
    :return-object="true"
    item-text="name"
    hide-details
    placeholder="Search..."
    spellcheck="false"
    :menu-props="{
      contentClass: 'dealer-switcher__dealer__menu',
    }"
    solo
    @change="select"
    @blur="value = currentDealer"
  >
    <template #item="{ item }">
      <VListItemContent>
        <VListItemTitle class="dealer-switcher__dealer-name">
          {{ item.name }}
        </VListItemTitle>
      </VListItemContent>
      <VListItemIcon
        v-if="item.id === currentDealer.id"
        class="dealer-switcher__current-dealer-icon"
      >
        <VIcon
          class="align-self-center"
          size="18px"
          v-text="'$checkedCircle'"
        />
      </VListItemIcon>
    </template>
  </VAutocomplete>
</template>

<style lang="scss">
$transition-timing-function: ease-out;
$input-max-width: 266px;

.dealer-switcher {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  max-width: $input-max-width;
  transition-duration: 0.18s;
  transition-property: border, box-shadow;
  transition-timing-function: $transition-timing-function;

  input {
    text-overflow: ellipsis;
    transition-duration: 0.2s;
    transition-property: font-size, margin;
    transition-timing-function: $transition-timing-function;
  }

  .v-input__slot {
    &[aria-expanded="true"] {
      border: 1px solid #FAFAFA;
    }

    .v-icon.primary--text {
      color: black !important;
    }
  }

  .v-input__prepend-inner {
    transition: visibility 0s, opacity 0.3s $transition-timing-function;
  }

  &:not(.v-select--is-menu-active) {
    box-shadow: none;

    .v-input__prepend-inner {
      opacity: 0;
      visibility: hidden;
    }

    input {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-left: -40px;
    }
  }

  &__dealer-name {
    font-size: 14px;
  }

  &__current-dealer-icon {
    .v-icon__svg {
      fill: var(--v-primary-base);
    }
  }

  &__activator {
    border-radius: 4px;
    height: 34px !important;
    width: 22px !important;
  }

  &__activator-icon {
    height: 18px !important;
    width: 14px !important;
  }

  .v-input__prepend-inner .v-icon__svg {
    height: 18px !important;
    width: 18px !important;
  }

  .v-input__append-inner {
    cursor: pointer;

    .v-icon__svg {
      height: 18px !important;
      width: 14px !important;
    }
  }

  &__dealer__menu {
    border-bottom: 1px solid #FAFAFA;
    border-left: 1px solid #FAFAFA;
    border-radius: 0 0 4px 4px !important;
    border-right: 1px solid #FAFAFA;
    max-width: $input-max-width !important;

    .v-list-item {
      margin-left: 8px;
      margin-right: 8px;

      &--active.primary--text {
        color: currentcolor !important;
      }

      &::before {
        border-radius: 4px;
      }
    }
  }
}

.theme--dark {
  .dealer-switcher {
    .v-input__slot {
      background: unset !important;

      &[aria-expanded="true"] {
        background-color: #1E1E1E !important;
        border: 1px solid #3A3A3C;
      }

      .v-icon.primary--text {
        color: white !important;
      }
    }

    &__dealer__menu {
      border-bottom: 1px solid #3A3A3C;
      border-left: 1px solid #3A3A3C;
      border-right: 1px solid #3A3A3C;

      .v-list-item--active.primary--text {
        color: white !important;
      }
    }
  }
}
</style>
